import React, { useState } from 'react'
import { Image, Button, Col, Form, Modal, Row, Alert, Container } from 'react-bootstrap'
import { Autocomplete, TextField } from '@mui/material'
import CONSTANTS from '../constants'
import './customer.css'
import { Appointment, PayingClient } from '../models/models'
import firebase from 'firebase'
import moment from 'moment'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

const Customer = (props: {
    showModal: boolean,
    setShowModal: any,
    customer?: PayingClient,
    customerId?: string,
    scheduleAppointment?: any,
    existingAppointment?: Appointment
}) => {

    /// First name of the customer
    const [firstName, setFirstName] = useState("")

    /// Last name of the customer
    const [lastName, setLastName] = useState("")

    /// Address of the customer
    const [address, setAddress] = useState("")

    /// Date that the customer got the service done
    const [dateOfService, setDateOfService] = useState<any>()

    /// If the client already has a service date, then this is displayed on the initial opening of the screen
    const [initialDateOfService, setInitialDateOfService] = useState<any>()

    /// Cost of the service provided for the customer
    const [costOfService, setCostOfService] = useState("")

    /// The phone number of the customer
    const [phoneNumber, setPhoneNumber] = useState("")

    /// How the customer found us
    const [howCustomerFoundUs, setHowCustomerFoundUs] = useState("")

    /// Service provided
    const [servicesProvided, setServicesProvided] = useState<string[]>([])

    /// Zip code of client
    const [zipCode, setZipCode] = useState("")

    /// Email
    const [email, setEmail] = useState("")

    /// Enter the notes for the customer
    const [notes, setNotes] = useState("")

    /// Whether we're currently saving information
    const [processing, setProcessing] = useState(false)

    /// Any error that has happened from firebase or user input
    const [error, setError] = useState("")

    const [customerId, setCustomerId] = useState<string>()

    const [deleteMode, setDeleteMode] = useState<boolean>(false)

    const [clients, setClients] = useState<PayingClient[]>([])

    const [startTime, setStartTime] = useState<string>("7:30 AM")

    const [endTime, setEndTime] = useState<string>("8:30 AM")

    const [customer, setCustomer] = useState<PayingClient>()

    useEffect(() => {
        if (customer) {
            setCustomerDetails(customer)
        }

    }, [customer])

    useEffect(() => {
        if (props.customerId) {
            firebase.firestore().collection('clients').doc(props.customerId).get().then(doc => {
                if (doc.exists) {
                    setCustomer(doc.data() as PayingClient)
                }
            })
        }

        if (props.customer) {
            setCustomer(props.customer)
        }

        // Initialize form with existing appointment data if available
        if (props.existingAppointment) {
            // Set appointment-specific fields
            // Handle different date formats that might be in the appointment
            let appointmentDate = props.existingAppointment.dateOfService;
            if (appointmentDate) {
                console.log("Raw date:", appointmentDate);

                // Handle moment objects (they have _isAMomentObject property)
                if (appointmentDate._isAMomentObject) {
                    setDateOfService(appointmentDate.toDate());
                }
                // Handle Firebase Timestamps (they have toDate method)
                else if (typeof appointmentDate.toDate === 'function') {
                    setDateOfService(appointmentDate.toDate());
                }
                // Handle Date objects
                else if (appointmentDate instanceof Date) {
                    setDateOfService(appointmentDate);
                }
                // Handle string dates
                else if (typeof appointmentDate === 'string') {
                    setDateOfService(new Date(appointmentDate));
                }
                // In all other cases, keep as is
                else {
                    setDateOfService(appointmentDate);
                }
            }

            // Log services to debug what's coming in
            console.log("Appointment services:", props.existingAppointment.servicesProvided, props.existingAppointment.services)

            // Try both servicesProvided and services arrays and ensure it's a proper array
            let services = props.existingAppointment.servicesProvided || props.existingAppointment.services || []

            // Make sure services is an array
            if (!Array.isArray(services)) {
                services = []
            }

            // Ensure we create a new array instance to trigger proper state updates
            setServicesProvided([...services])
            console.log("Setting services to:", services)
            setCostOfService(props.existingAppointment.total ? String(props.existingAppointment.total) : "")
            setNotes(props.existingAppointment.notes || "")

            // Format time values correctly for the time input fields
            let formattedStartTime = props.existingAppointment.startTime || "07:30"
            if (formattedStartTime.includes("AM") || formattedStartTime.includes("PM")) {
                // Convert 12-hour format to 24-hour format if needed
                formattedStartTime = moment(formattedStartTime, ["h:mm A", "HH:mm"]).format("HH:mm")
            }

            let formattedEndTime = props.existingAppointment.endTime || "08:30"
            if (formattedEndTime.includes("AM") || formattedEndTime.includes("PM")) {
                // Convert 12-hour format to 24-hour format if needed
                formattedEndTime = moment(formattedEndTime, ["h:mm A", "HH:mm"]).format("HH:mm")
            }

            setStartTime(formattedStartTime)
            setEndTime(formattedEndTime)

            console.log("Editing appointment:", {
                date: props.existingAppointment.dateOfService,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                cost: props.existingAppointment.total,
                services: props.existingAppointment.servicesProvided
            })
        }

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return
        } else {
            // loadCustomers()
        }

    }, [props.customer, props.existingAppointment])

    const setCustomerDetails = (customer: PayingClient) => {
        // Only set basic customer information
        setFirstName(customer.firstName ?? "")
        setLastName(customer.lastName ?? "")
        setAddress(customer.address ?? "")
        setPhoneNumber(customer.phoneNumber ?? "")
        setZipCode(customer.zipCode ?? "")
        setEmail(customer.email ?? "")

        // Also set customer ID for reference
        setCustomerId(customer.id ?? "")

        // Don't load appointment-specific fields
        // setDateOfService(customer.dateOfService)
        // setCostOfService(customer.costOfService ? String(customer.costOfService) : "")
        // setHowCustomerFoundUs(customer.howCustomerFoundUs ?? "Google")
        // setServicesProvided(customer.servicesProvided ?? [])
        // setNotes(customer.notes ?? "")
        // setInitialDateOfService(customer.dateOfService ?? "")
    }

    const saveCustomer = (event: any) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        const db = firebase.firestore()
        setProcessing(true)

        let myCustomer: PayingClient = customer ?? {
            firstName: firstName,
            lastName: lastName,
            address: address,
            phoneNumber: phoneNumber,
            zipCode: zipCode,
            email: email,
            notes: notes,
            howCustomerFoundUs: howCustomerFoundUs,
            id: customerId ?? uuidv4()
        }

        if (props.scheduleAppointment) {
            const appointment: Appointment = {
                clientId: myCustomer.id,
                dateOfService: dateOfService,
                // Use existing appointment ID if editing, otherwise create new
                id: props.existingAppointment ? props.existingAppointment.id : uuidv4(),
                services: servicesProvided,
                servicesProvided: servicesProvided,
                total: costOfService ? Number(costOfService) : 0,
                notes: notes,
                misc: myCustomer.misc ?? [],
                startTime: startTime,
                endTime: endTime
            }

            props.scheduleAppointment(appointment, myCustomer)

            let appointments = myCustomer.appointments ?? []

            // If updating existing appointment, replace it in the array
            if (props.existingAppointment) {
                const index = appointments.findIndex(a => a.id === props.existingAppointment?.id)
                if (index >= 0) {
                    appointments[index] = appointment
                } else {
                    appointments.push(appointment)
                }
            } else {
                // New appointment
                appointments.push(appointment)
            }

            myCustomer.appointments = appointments
        }

        if (customerId) {
            db.collection(CONSTANTS.FIRESTORE.CLIENTS).doc(customerId).set(myCustomer, { merge: true }).then(result => {
                props.setShowModal(false)
            }).catch(error => {
                setError(error)
            })

            return
        }

        db.collection(CONSTANTS.FIRESTORE.CLIENTS).doc(myCustomer.id).set(myCustomer).then(result => {
            props.setShowModal(false)
        }).catch(error => {
            setError(error)
        })

    }

    const serviceClicked = (event: any) => {
        const service = event.target.name
        const isChecked = event.target.checked

        console.log(`Service ${service} ${isChecked ? 'checked' : 'unchecked'}`, {
            currentServices: servicesProvided,
            exists: servicesProvided.includes(service)
        })

        if (isChecked && !servicesProvided.includes(service)) {
            // Add service if checked and not already in the array
            const myServicesProvided = [...servicesProvided, service]
            console.log("Adding service, new array:", myServicesProvided)
            setServicesProvided(myServicesProvided)
        } else if (!isChecked) {
            // Remove service if unchecked
            const myServicesProvided = servicesProvided.filter(s => s !== service)
            console.log("Removing service, new array:", myServicesProvided)
            setServicesProvided(myServicesProvided)
        }
    }

    const getCustomers = async (name: String) => {
        const db = firebase.firestore()
        const myClients = (await db.collection('clients').where('firstName', '>=', name)
            .get()).docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                } as PayingClient
            })

        setClients(myClients)
    }

    const deleteCustomer = () => {

        let customerId = props.customer?.id

        if (!customerId) { return }

        const db = firebase.firestore()
        db.collection(CONSTANTS.FIRESTORE.CLIENTS).doc(customerId).update({
            archived: true
        })

        setDeleteMode(false)
        props.setShowModal(false)

    }

    return (
        <div>
            <Modal show={deleteMode} size="sm" dialogClassName="delete-modal" centered>
                <Modal.Header className="delete-modal-header">
                    <Modal.Title className="text-danger">Archive Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="delete-modal-body">
                    <p className="mb-4">Are you sure you want to archive this customer?</p>
                    <div className="customer-details p-3 mb-4 rounded">
                        <div className="d-flex">
                            <span className="material-icons me-2 text-muted">person</span>
                            <span className="fw-bold">{props.customer?.firstName} {props.customer?.lastName}</span>
                        </div>
                        <div className="d-flex mt-2">
                            <span className="material-icons me-2 text-muted">location_on</span>
                            <span>{props.customer?.address}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Button variant="outline-secondary" onClick={() => setDeleteMode(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteCustomer}>
                            Yes, Archive
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => props.setShowModal(false)}
                show={props.showModal}
                size="lg"
                dialogClassName="customer-modal"
                aria-labelledby="customer-modal-title"
                centered
            >
                <Form onSubmit={saveCustomer} >
                    <Modal.Header closeButton className="modal-header-custom">
                        <Modal.Title>
                            {props.existingAppointment
                                ? `Edit Appointment: ${firstName} ${lastName}`
                                : customer
                                    ? `Edit Customer: ${firstName} ${lastName}`
                                    : 'New Customer'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-custom">
                        <div className="section-card">
                            <div className="section-header">
                                <h5>Customer Search</h5>
                                <small className="text-muted">Search for an existing customer or create a new one</small>
                            </div>
                            <Row>
                                <Col>
                                    <Autocomplete
                                        className="my-2"
                                        id="grouped-customers"
                                        options={clients.sort((a, b) => -b.firstName.localeCompare(a.firstName))}
                                        groupBy={(client) => client.firstName.charAt(0).toUpperCase()}
                                        getOptionLabel={(client) => `${client.firstName} ${client.lastName ?? ""}`}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            onChange={(event) => {
                                                if (event.target.value != "") {
                                                    getCustomers(event.target.value)
                                                }
                                            }}
                                            label="Search for existing customer" />}
                                        fullWidth={true}
                                        onChange={(event, value) => {
                                            if (value) {
                                                setCustomer(value as PayingClient)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="section-card">
                            <div className="section-header">
                                <h5>Customer Information</h5>
                                <small className="text-muted">Basic customer details</small>
                            </div>
                            <Row>
                                <Col md="6">
                                    <Form.Group controlId="formFirstName">
                                        <TextField
                                            value={firstName}
                                            onChange={(event) => setFirstName(event.target.value)}
                                            label="First Name"
                                            required
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text" placeholder="Enter the customer's first name" />
                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formLastName">
                                        <TextField
                                            value={lastName}
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            onChange={(event) => setLastName(event.target.value)}
                                            type="text" placeholder="Enter the customer's last name" />
                                    </Form.Group>
                                </Col>

                                <Col md="12">
                                    <Form.Group className="mb-3" controlId="formAddress">
                                        <TextField
                                            value={address}
                                            label="Address"
                                            variant="outlined"
                                            required
                                            fullWidth={true}
                                            onChange={(event) => setAddress(event.target.value)}
                                            type="text" placeholder="Enter the customer's address" />
                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formZipCode">
                                        <TextField
                                            label="Zip Code"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={zipCode}
                                            onChange={(event) => setZipCode(event.target.value)}
                                            type="text" placeholder="Enter the customer's zip code" />
                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            type="text" placeholder="Enter the customer's email" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <div className="section-card">
                            <div className="section-header">
                                <h5>Service Details</h5>
                                <small className="text-muted">Appointment and service information</small>
                            </div>
                            <Row>
                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formDate">
                                        <Form.Label>Date of service</Form.Label>
                                        {initialDateOfService && (
                                            <div className="small text-muted mb-1">
                                                Initial date: {initialDateOfService != undefined ? moment.unix(initialDateOfService.seconds).format('YYYY-MM-DD') : ""}
                                            </div>
                                        )}
                                        <Form.Control
                                            className="form-control-modern"
                                            value={dateOfService ?
                                                (dateOfService.toDate ?
                                                    moment(dateOfService.toDate()).format('YYYY-MM-DD') :
                                                    dateOfService instanceof Date ?
                                                        moment(dateOfService).format('YYYY-MM-DD') :
                                                        moment(new Date(dateOfService)).format('YYYY-MM-DD')
                                                ) : ''}
                                            onChange={(event) => setDateOfService(moment(event.target.value, 'YYYY-MM-DD').toDate())}
                                            type="date" placeholder="Enter the date of the service" />
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-3">
                                        <TextField
                                            id="start-time"
                                            label="Start Time"
                                            type="time"
                                            value={startTime || "07:30"}
                                            variant="outlined"
                                            onChange={(event) => {
                                                setStartTime(event.target.value)
                                            }}
                                            className="w-100"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 300 }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md="3">
                                    <Form.Group className="mb-3">
                                        <TextField
                                            id="end-time"
                                            label="End Time"
                                            type="time"
                                            variant="outlined"
                                            onChange={(event) => {
                                                setEndTime(event.target.value)
                                            }}
                                            value={endTime || "08:30"}
                                            className="w-100"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 300 }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formCost">
                                        <TextField
                                            label="Cost of Service"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={costOfService}
                                            InputProps={{
                                                startAdornment: <span style={{ marginRight: '8px' }}>$</span>,
                                            }}
                                            onChange={(event) => setCostOfService(event.target.value)}
                                            type="number"
                                            placeholder="How much was the service for?" />
                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                                        <TextField
                                            label="Phone Number"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={phoneNumber}
                                            onChange={(event) => setPhoneNumber(event.target.value)}
                                            type="tel" placeholder="Enter their phone number" />
                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Form.Group className="mb-3" controlId="formHowFindUs">
                                        <Form.Label>How did they find us?</Form.Label>
                                        <Form.Control
                                            className="form-control-modern"
                                            onChange={(event) => setHowCustomerFoundUs(event.target.value)}
                                            defaultValue={CONSTANTS.HOWFOUNDUS.GOOGLE}
                                            value={howCustomerFoundUs}
                                            as="select">
                                            <option>{CONSTANTS.HOWFOUNDUS.GOOGLE}</option>
                                            <option>{CONSTANTS.HOWFOUNDUS.YELP}</option>
                                            <option>{CONSTANTS.HOWFOUNDUS.THUMBTACK}</option>
                                            <option>{CONSTANTS.HOWFOUNDUS.CUSTOMER_REFERRAL}</option>
                                            <option>{CONSTANTS.HOWFOUNDUS.BUSINESS_REFERRAL}</option>
                                            <option>{CONSTANTS.HOWFOUNDUS.NEW_HOME_RESOURCE}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col md="12">
                                    <div className="section-card mt-3">
                                        <div className="section-header">
                                            <h5>Services</h5>
                                            <small className="text-muted">Select all services provided</small>
                                        </div>
                                        <Form.Group className="mb-3">
                                            <div className="service-checkboxes">
                                                <div className="services-grid">
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-power-washing"
                                                            name={CONSTANTS.SERVICES.POWER_WASHING}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.POWER_WASHING)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-power-washing">
                                                            {CONSTANTS.SERVICES.POWER_WASHING}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-ext-window-cleaning"
                                                            name={CONSTANTS.SERVICES.EXT_WINDOW_CLEANING}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.EXT_WINDOW_CLEANING)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-ext-window-cleaning">
                                                            {CONSTANTS.SERVICES.EXT_WINDOW_CLEANING}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-int-window-cleaning"
                                                            name={CONSTANTS.SERVICES.INT_WINDOW_CLEANING}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.INT_WINDOW_CLEANING)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-int-window-cleaning">
                                                            {CONSTANTS.SERVICES.INT_WINDOW_CLEANING}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-window-cleaning"
                                                            name={CONSTANTS.SERVICES.WINDOW_CLEANING}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.WINDOW_CLEANING)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-window-cleaning">
                                                            {CONSTANTS.SERVICES.WINDOW_CLEANING}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-screen-building"
                                                            name={CONSTANTS.SERVICES.SCREEN_BUILDING}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.SCREEN_BUILDING)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-screen-building">
                                                            {CONSTANTS.SERVICES.SCREEN_BUILDING}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-screen-repair"
                                                            name={CONSTANTS.SERVICES.SCREEN_REPAIR}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.SCREEN_REPAIR)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-screen-repair">
                                                            {CONSTANTS.SERVICES.SCREEN_REPAIR}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-window-tint"
                                                            name={CONSTANTS.SERVICES.WINDOW_TINT}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.WINDOW_TINT)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-window-tint">
                                                            {CONSTANTS.SERVICES.WINDOW_TINT}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-solar-screen-build"
                                                            name={CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-solar-screen-build">
                                                            {CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-solar-screen-repair"
                                                            name={CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-solar-screen-repair">
                                                            {CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-solar-screen-estimate"
                                                            name={CONSTANTS.SERVICES.SOLAR_SCREEN_ESTIMATE}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.SOLAR_SCREEN_ESTIMATE)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-solar-screen-estimate">
                                                            {CONSTANTS.SERVICES.SOLAR_SCREEN_ESTIMATE}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-window-cleaning-estimate"
                                                            name={CONSTANTS.SERVICES.WINDOW_CLEANING_ESTIMATE}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.WINDOW_CLEANING_ESTIMATE)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-window-cleaning-estimate">
                                                            {CONSTANTS.SERVICES.WINDOW_CLEANING_ESTIMATE}
                                                        </label>
                                                    </div>
                                                    <div className="custom-checkbox service-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="service-window-tint-estimate"
                                                            name={CONSTANTS.SERVICES.WINDOW_TINT_ESTIMATE}
                                                            checked={servicesProvided.includes(CONSTANTS.SERVICES.WINDOW_TINT_ESTIMATE)}
                                                            onChange={(event) => serviceClicked(event)}
                                                        />
                                                        <label htmlFor="service-window-tint-estimate">
                                                            {CONSTANTS.SERVICES.WINDOW_TINT_ESTIMATE}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Col>

                                <Col md="12">
                                    <Form.Group className="mb-3" controlId="formNotes">
                                        <TextField
                                            value={notes}
                                            onChange={(event) => setNotes(event.target.value)}
                                            multiline={true}
                                            rows={4}
                                            fullWidth={true}
                                            variant="outlined"
                                            label="Notes"
                                            placeholder="What are the details of this job? For example, is it two story? How many square feet?" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer-custom">
                        <div className="d-flex justify-content-between w-100">
                            <div>
                                {customerId && (
                                    <Button variant="outline-danger" onClick={() => setDeleteMode(true)}>
                                        <span className="material-icons align-middle me-1" style={{ fontSize: '16px' }}>delete</span>
                                        Archive
                                    </Button>
                                )}
                            </div>
                            <div className="d-flex align-items-center">
                                {error && (
                                    <Alert variant="danger" className="mb-0 me-3 py-1 px-3">
                                        {error}
                                    </Alert>
                                )}
                                {processing && <Image src="loading.gif" height="30" className="me-2" />}
                                <Button variant="outline-secondary" className="me-2" onClick={() => props.setShowModal(false)}>Cancel</Button>
                                <Button
                                    variant="primary"
                                    onClick={saveCustomer}
                                    disabled={processing}
                                >
                                    {props.existingAppointment ? 'Update Appointment' : 'Save Customer'}
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>

                </Form>
            </Modal>
        </div >
    )

}

export default Customer