import { TextField, Button as MaterialButton, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Alert, Autocomplete } from '@mui/material';
import firebase from 'firebase';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Accordion, Image, Modal, InputGroup, Table, Badge } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CONSTANTS from '../constants';
import { PayingClient } from '../models/models';
import QuickbooksService from '../services/QuickbooksService';

type Dimension = {
    length: Fraction,
    width: Fraction,
    image?: string  // Base64 encoded image data
}

type Fraction = {
    whole: number | undefined | null,
    top?: number | undefined | null,
    bottom?: number | undefined | null
}

type Screen = {
    id: string,
    dimension: Dimension,
    materialCost: number,
    laborCost: number,
    price: number,
    archedPrice: number,
    actualPrice?: number,
    rescreenPrice: number,
    image?: string,  // Base64 encoded image data
}

type AddOn = {
    name: string,
    price: number
}

type SelectedService = {
    id: string,
    name: string,
    price: number,
    dimensions: Dimension,
    addOns?: AddOn[],
    type: string
}

type Customer = {
    name: string,
    email: string,
    phone: string,
    address: string,
    id?: string  // Reference to existing customer in Firebase
}

type Estimate = {
    id: string,
    customerId?: string,
    customerName: string,
    dimensions: Dimension[],
    solarScreens: Screen[],
    meshColor: string,
    frameColor: string,
    discount: number,
    notes: string,
    costPerSquareFoot: number,
    dateCreated?: firebase.firestore.Timestamp,
    dateModified: firebase.firestore.Timestamp,
    totalPrice: number,
    status?: string, // 'draft' or 'final'
    selectedServices?: Record<string, any> // Store selected services for each screen
}

const Screens = () => {
    const [selectedServices, setSelectedServices] = useState<Record<string, any>>({});

    const addRows = (): Dimension[] => {
        console.log('Creating empty dimension rows');
        const dimensions: Dimension[] = []

        for (let index = 0; index < 1; index++) {
            dimensions.push({
                length: {
                    whole: null, // Set initially empty
                    top: null,   // Set explicitly to null, not undefined
                    bottom: null // Set explicitly to null, not undefined
                },
                width: {
                    whole: null, // Set initially empty
                    top: null,   // Set explicitly to null, not undefined
                    bottom: null // Set explicitly to null, not undefined
                }
            })
        }

        console.log('Created dimension rows:', JSON.stringify(dimensions));
        return dimensions
    }

    const [dimensions, setDimensions] = useState<Dimension[]>(addRows())

    const [screens, setScreens] = useState<Screen[]>([])

    const [solarScreens, setSolarScreens] = useState<Screen[]>([])

    const [totalProfit, setTotalProfit] = useState({
        solarScreens: 0,
        screens: 0
    })

    // const [selectedServices, setSelectedServices] = useState<Object>({})

    const [error, setError] = useState('')

    const [meshColor, setMeshColor] = useState('')

    const [frameColor, setFrameColor] = useState('')

    const meshColors = ["Black", "Stucco", "Gray", "Beige", "White", "Brown"]
    const frameColors = ["Tan", "White", "Bronze", "Silver", "Champagne"]

    const [notes, setNotes] = useState('')

    // Existing clients from the database
    const [clients, setClients] = useState<PayingClient[]>([])

    const cost = {
        normal: {
            framing: 0.68,
            pullTabs: 0.54,
            springs: 0.69,
            corners: 0.88,
            mesh: 0.15
        },
        solar: {
            framing: 1.3,
            standOffFrame: 1.37,
            corners: 1.33,
            mesh: .85,
            mesh90: 1,
            clips: 1.2,
        }
    }

    const colPadding = { paddingLeft: 5, paddingRight: 5 }

    const [costPerSquareFoot, setCostPerSquareFoot] = useState(14.0)

    const [discount, setDiscount] = useState(0)

    const [customer, setCustomer] = useState<Customer>({
        name: '',
        email: '',
        phone: '',
        address: ''
    })

    // Image preview modal states
    const [showImageModal, setShowImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState<string>("")
    const [selectedDimensionIndex, setSelectedDimensionIndex] = useState<number>(-1)

    // Estimate states
    const [currentEstimateId, setCurrentEstimateId] = useState<string>("")
    const [estimates, setEstimates] = useState<Estimate[]>([])
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [filteredEstimates, setFilteredEstimates] = useState<Estimate[]>([])
    const [showEstimateModal, setShowEstimateModal] = useState<boolean>(false)
    const [autoSaveTimer, setAutoSaveTimer] = useState<NodeJS.Timeout | null>(null)
    const [lastSaved, setLastSaved] = useState<Date | null>(null)

    // Initial load of estimates
    useEffect(() => {


        if (!firebase.apps.length) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }

        loadEstimates();
    }, []);

    // Effect for total profit calculation
    useEffect(() => {
        setTotalProfit(getTotalProfit())
    }, [solarScreens])

    // Effect for auto-saving whenever relevant data changes
    useEffect(() => {
        // Only auto-save if we have some meaningful data
        if (dimensions.some(d => (d.length.whole !== undefined && d.length.whole !== null && d.length.whole > 0) ||
            (d.width.whole !== undefined && d.width.whole !== null && d.width.whole > 0)) ||
            customer.name ||
            solarScreens.length > 0) {

            // Clear any existing timer
            if (autoSaveTimer) {
                clearTimeout(autoSaveTimer);
            }

            // Set a new timer for auto-save (2 seconds after last change)
            const timer = setTimeout(() => {
                // Before auto-saving, make sure screens and selected services are in sync
                // for any dimensions that have changed
                autoSaveEstimate();
            }, 2000);

            setAutoSaveTimer(timer);
        }

        // Cleanup on component unmount
        return () => {
            if (autoSaveTimer) {
                clearTimeout(autoSaveTimer);
            }
        };
    }, [dimensions, customer, solarScreens, meshColor, frameColor, notes, discount, costPerSquareFoot, selectedServices])

    // Filter estimates when search term changes
    useEffect(() => {
        if (!searchTerm) {
            setFilteredEstimates(estimates);
            return;
        }

        const filtered = estimates.filter(estimate =>
            estimate.customerName.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredEstimates(filtered);
    }, [searchTerm, estimates])

    const addRow = () => {
        console.log('Adding empty dimension row');
        setDimensions([...dimensions, {
            length: {
                whole: null, // Empty value
                top: undefined,
                bottom: undefined
            },
            width: {
                whole: null, // Empty value
                top: undefined,
                bottom: undefined
            }
        }]);
    }

    const addSelectedService = (service: SelectedService) => {
        // If this service is already selected, toggle it off (remove it)
        if (selectedServices[service.id]) {
            const newSelectedServices = { ...selectedServices };
            delete newSelectedServices[service.id];
            setSelectedServices(newSelectedServices);
        } else {
            // Otherwise add it to the selected services
            setSelectedServices({ ...selectedServices, [service.id]: service });
        }
    }

    // Helper function to create a unique key for a dimension
    const getDimensionKey = (dimension: Dimension): string => {
        const lengthWhole = dimension.length.whole ?? 0;
        const widthWhole = dimension.width.whole ?? 0;
        return `${lengthWhole}_${dimension.length.top || 0}_${dimension.length.bottom || 0}_${widthWhole}_${dimension.width.top || 0}_${dimension.width.bottom || 0}`;
    };

    // Helper function to find a screen that matches a dimension
    const findScreenForDimension = (dimension: Dimension, screens: Screen[]): Screen | undefined => {
        if (!dimension || !screens || screens.length === 0) {
            return undefined;
        }

        const dimensionKey = getDimensionKey(dimension);
        return screens.find(screen =>
            getDimensionKey(screen.dimension) === dimensionKey
        );
    };

    // Helper function to handle dimension changes and update selected services accordingly
    const handleDimensionChange = (updatedDimensions: Dimension[], index: number) => {
        // If we have solar screens, check if this specific dimension had a screen before
        if (solarScreens && solarScreens.length > 0) {
            const oldDimension = dimensions[index];
            const oldDimensionKey = oldDimension ? getDimensionKey(oldDimension) : '';
            const newDimension = updatedDimensions[index];
            const newDimensionKey = getDimensionKey(newDimension);

            // Skip if the dimension hasn't actually changed
            if (oldDimensionKey === newDimensionKey) {
                return;
            }

            // Find screen matching exactly this old dimension
            // We need to be careful to match only the exact screen for this dimension
            const oldScreen = solarScreens.find(screen => {
                const screenKey = getDimensionKey(screen.dimension);

                // Make sure dimensions match exactly 
                return screenKey === oldDimensionKey && (
                    // Plus check if image matches if available
                    (screen.dimension.image === oldDimension.image) ||
                    (!screen.dimension.image && !oldDimension.image)
                );
            });

            if (oldScreen) {
                // This specific dimension had a screen before
                // Create a new screen with updated dimensions but keeping the same ID
                const updatedScreen = updateScreenWithDimension(oldScreen, newDimension);

                // Update the single screen in solarScreens
                const updatedSolarScreens = solarScreens.map(screen =>
                    screen.id === oldScreen.id ? updatedScreen : screen
                );

                // Remove this screen from selectedServices if it was selected
                if (selectedServices[oldScreen.id]) {
                    const newSelectedServices = { ...selectedServices };
                    delete newSelectedServices[oldScreen.id];
                    setSelectedServices(newSelectedServices);
                }

                // Update the screens
                setSolarScreens(updatedSolarScreens);
            }
        }
    };

    // Helper function to calculate price for a dimension
    const calculatePriceForDimension = (dimension: Dimension, pricePerSqFt: number): number => {
        if (!dimension || !dimension.length.whole || !dimension.width.whole) {
            return 0;
        }

        const lengthAsDecimal = dimension.length.whole + ((dimension.length.top ?? 0) / (dimension.length.bottom ?? 1));
        const widthAsDecimal = dimension.width.whole + ((dimension.width.top ?? 0) / (dimension.width.bottom ?? 1));

        // Calculate price based on square footage
        const squareFeet = (lengthAsDecimal / 12) * (widthAsDecimal / 12);
        const price = squareFeet * pricePerSqFt;

        // Apply minimum price if needed
        return price < 100 ? 100 : price;
    };

    // Helper function to update a single screen with new dimensions
    const updateScreenWithDimension = (screen: Screen, dimension: Dimension): Screen => {
        // Handle undefined/null values
        const lengthWhole = dimension.length.whole ?? 0;
        const widthWhole = dimension.width.whole ?? 0;

        let lengthAsDecimal = lengthWhole + ((dimension.length.top ?? 0) / (dimension.length.bottom ?? 1));
        let widthAsDecimal = widthWhole + ((dimension.width.top ?? 0) / (dimension.width.bottom ?? 1));

        const solarSreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * costPerSquareFoot;
        const rescreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * 7;

        const fullLength = numberToFraction(`${lengthAsDecimal}`);
        const fullWidth = numberToFraction(`${widthAsDecimal}`);

        lengthAsDecimal -= 1.5;
        widthAsDecimal -= 1.5;

        const solarFrameCost = (((lengthAsDecimal * 2) / 12) * cost.solar.framing) + (((widthAsDecimal * 2) / 12) * cost.solar.framing);
        const solarMeshCost = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * cost.solar.mesh;
        const totalSolarScreenCost = solarFrameCost + solarMeshCost + cost.solar.corners + cost.solar.clips;

        // Create updated screen with new values
        const updatedScreen: Screen = {
            ...screen,
            dimension: {
                length: fullLength,
                width: fullWidth,
                image: dimension.image
            },
            materialCost: totalSolarScreenCost,
            laborCost: 20,
            price: solarSreenPrice,
            archedPrice: solarSreenPrice + 75,
            rescreenPrice: rescreenPrice
        };

        // Apply minimum prices
        if (updatedScreen.price < 100) {
            updatedScreen.actualPrice = updatedScreen.price;
            updatedScreen.price = 100;
        }

        if (updatedScreen.rescreenPrice < 40) {
            updatedScreen.rescreenPrice = 40;
        }

        return updatedScreen;
    };

    /// Calculate the size that we need to cut the frames and the price of the material as well as how much we're going to make from the screens
    /// also we estimate how long it should take and calculate that into profit
    const calculateScreens = () => {
        // Use the ID-preserving calculation function with existing solarScreens
        // This will preserve IDs for existing screens and only generate new IDs for new dimensions
        return calculateScreensWithIds(solarScreens);
    }

    // Version of calculateScreens that preserves existing screen IDs when possible
    // This is important for maintaining selected services when loading an estimate
    const calculateScreensWithIds = (existingScreens: Screen[]): Screen[] => {
        const mySolarScreens: Screen[] = []
        const costPerSquareFootSolar = costPerSquareFoot

        // Create a map to track which dimensions have been processed
        // and their corresponding screen IDs
        const dimensionMap = new Map<string, string[]>();

        // First, build a map of dimension keys to screen IDs from existing screens
        if (existingScreens && existingScreens.length > 0) {
            existingScreens.forEach(screen => {
                const key = getDimensionKey(screen.dimension);
                if (dimensionMap.has(key)) {
                    dimensionMap.get(key)?.push(screen.id);
                } else {
                    dimensionMap.set(key, [screen.id]);
                }
            });
        }

        // Create a tracking set for dimensions we've processed
        const processedDimensions = new Set<number>();

        // Step 1: First, match existing screens with current dimensions
        if (existingScreens && existingScreens.length > 0) {
            dimensions.forEach((dimension, index) => {
                // Skip invalid dimensions
                if (!dimension ||
                    dimension.length.whole === undefined ||
                    dimension.length.whole === null ||
                    dimension.length.whole === 0 ||
                    dimension.width.whole === undefined ||
                    dimension.width.whole === null ||
                    dimension.width.whole === 0) {
                    return;
                }

                const dimensionKey = getDimensionKey(dimension);
                const existingScreenIds = dimensionMap.get(dimensionKey) || [];

                // Check if we have any existing screens for this dimension
                if (existingScreenIds.length > 0) {
                    // Get the first matching screen ID that hasn't been used yet
                    const screenId = existingScreenIds.shift();
                    if (screenId) {
                        // Find the actual screen
                        const existingScreen = existingScreens.find(s => s.id === screenId);
                        if (existingScreen) {
                            // This screen's dimension still exists, so keep the screen with its existing ID
                            // but update any calculations if needed
                            mySolarScreens.push(updateScreenWithDimension(existingScreen, dimension));

                            // Mark this dimension as processed
                            processedDimensions.add(index);
                        }
                    }
                }
            });
        }

        // Skip further calculation if no dimensions
        if (!dimensions || dimensions.length === 0) {
            return mySolarScreens;
        }

        // Step 2: Now create screens for dimensions that don't already have screens
        dimensions.forEach((dimension, index) => {
            // Skip dimensions that already have screens
            if (processedDimensions.has(index)) {
                return;
            }

            // Skip dimensions with no values
            if (!dimension ||
                dimension.length.whole === undefined ||
                dimension.length.whole === null ||
                dimension.length.whole === 0 ||
                dimension.width.whole === undefined ||
                dimension.width.whole === null ||
                dimension.width.whole === 0) {
                return;
            }

            // Convert undefined/null to 0 for calculations
            const lengthWhole = dimension.length.whole ?? 0;
            const widthWhole = dimension.width.whole ?? 0;

            let lengthAsDecimal = lengthWhole + ((dimension.length.top ?? 0) / (dimension.length.bottom ?? 1))
            let widthAsDecimal = widthWhole + ((dimension.width.top ?? 0) / (dimension.width.bottom ?? 1))

            // Skip invalid dimensions
            if (isNaN(lengthAsDecimal) || isNaN(widthAsDecimal) || lengthAsDecimal <= 0 || widthAsDecimal <= 0) {
                return;
            }

            const normalScreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * 5
            const solarSreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * costPerSquareFootSolar
            const rescreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * 7

            const fullLength = numberToFraction(`${lengthAsDecimal}`)
            const fullWidth = numberToFraction(`${widthAsDecimal}`)

            lengthAsDecimal -= 1.5
            widthAsDecimal -= 1.5

            const normalFrameCost = (((lengthAsDecimal * 2) / 12) * cost.normal.framing) + (((widthAsDecimal * 2) / 12) * cost.normal.framing)
            const meshCost = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * cost.normal.mesh

            const solarFrameCost = (((lengthAsDecimal * 2) / 12) * cost.solar.framing) + (((widthAsDecimal * 2) / 12) * cost.solar.framing)
            const solarMeshCost = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * cost.solar.mesh

            const totalNormalScreenCost = normalFrameCost + meshCost + cost.normal.pullTabs + cost.normal.springs + cost.normal.corners
            const totalSolarScreenCost = solarFrameCost + solarMeshCost + cost.solar.corners + cost.solar.clips

            // Create a new screen with a new ID
            const solarScreen: Screen = {
                dimension: {
                    length: fullLength,
                    width: fullWidth,
                    image: dimension.image  // Include the image if it exists
                },
                materialCost: totalSolarScreenCost,
                laborCost: 20,
                price: solarSreenPrice,
                archedPrice: solarSreenPrice + 75,
                rescreenPrice: rescreenPrice,
                id: uuidv4() // Generate a new ID for new dimensions
            }

            if (solarScreen.price < 100) {
                solarScreen.actualPrice = solarScreen.price
                solarScreen.price = 100
            }

            if (solarScreen.rescreenPrice < 40) {
                solarScreen.rescreenPrice = 40
            }

            mySolarScreens.push(solarScreen)
        });

        // Update the solar screens state
        setSolarScreens(mySolarScreens)
        return mySolarScreens
    }

    // Fetch customers from Firestore based on search term
    const getCustomers = async (searchTerm: string) => {
        if (!searchTerm || searchTerm.length < 2) return;

        const db = firebase.firestore();
        try {
            // Search by first name
            const firstNameSnapshot = await db.collection(CONSTANTS.FIRESTORE.CLIENTS)
                .where('firstName', '>=', searchTerm)
                .where('firstName', '<=', searchTerm + '\uf8ff')
                .limit(10)
                .get();

            // Search by last name
            const lastNameSnapshot = await db.collection(CONSTANTS.FIRESTORE.CLIENTS)
                .where('lastName', '>=', searchTerm)
                .where('lastName', '<=', searchTerm + '\uf8ff')
                .limit(10)
                .get();

            // Combine results
            const results: PayingClient[] = [];

            firstNameSnapshot.forEach(doc => {
                results.push(doc.data() as PayingClient);
            });

            lastNameSnapshot.forEach(doc => {
                // Avoid duplicates
                if (!results.some(client => client.id === doc.id)) {
                    results.push(doc.data() as PayingClient);
                }
            });

            setClients(results);
        } catch (error) {
            console.error("Error searching for customers:", error);
        }
    };

    const numberToFraction = (amount: string): Fraction => {
        // This is a whole number and doesn't need modification.
        if (parseFloat(amount) === parseInt(amount)) {
            return {
                whole: Number(amount)
            };
        }
        // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
        var gcd = function (a: number, b: number): number {
            if (b < 0.0000001) {
                return a;
            }
            return gcd(b, Math.floor(a % b));
        };

        var len = amount.toString().length - 2;
        var denominator = Math.pow(10, len);
        var numerator = parseFloat(amount) * denominator;
        var divisor = gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        var base = 0;
        // In a scenario like 3/2, convert to 1 1/2
        // by pulling out the base number and reducing the numerator.
        if (numerator > denominator) {
            base = Math.floor(numerator / denominator);
            numerator -= base * denominator;
        }

        const fraction: Fraction = {
            whole: base,
            top: Math.floor(numerator),
            bottom: Math.floor(denominator)
        }

        return fraction
    };

    const getTotalProfit = () => {
        let materialCost = solarScreens.reduce((sum, screen) => sum + screen.materialCost, 0)
        let laborCost = solarScreens.reduce((sum, screen) => sum + screen.laborCost, 0)
        let price = solarScreens.reduce((sum, screen) => sum + screen.price, 0)

        const solarScreenProfit = price - laborCost - materialCost

        materialCost = screens.reduce((sum, screen) => sum + screen.materialCost, 0)
        laborCost = screens.reduce((sum, screen) => sum + screen.laborCost, 0)
        price = screens.reduce((sum, screen) => sum + screen.price, 0)

        const screenProfit = price - laborCost - materialCost

        return {
            solarScreens: solarScreenProfit,
            screens: screenProfit
        }
    }

    // Compress image to less than 75kb
    const compressImage = (dataUrl: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                // Calculate aspect ratio to maintain proportions
                const MAX_WIDTH = 1000;
                const MAX_HEIGHT = 1000;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error('Could not get canvas context'));
                    return;
                }

                ctx.drawImage(img, 0, 0, width, height);

                // Start with a high quality and reduce until file size is under 75kb
                let quality = 0.9;
                let compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
                let byteSize = Math.round((compressedDataUrl.length - 'data:image/jpeg;base64,'.length) * 0.75);

                // If already under 75kb, return this version
                if (byteSize <= 75 * 1024) {
                    resolve(compressedDataUrl);
                    return;
                }

                // Otherwise iteratively compress until we get under 75kb
                const attemptCompress = () => {
                    quality -= 0.1;
                    if (quality <= 0.1) quality = 0.1; // Don't go below 0.1

                    compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
                    byteSize = Math.round((compressedDataUrl.length - 'data:image/jpeg;base64,'.length) * 0.75);

                    if (byteSize <= 75 * 1024 || quality <= 0.1) {
                        console.log(`Compressed image to ${(byteSize / 1024).toFixed(2)}kb with quality ${quality}`);
                        resolve(compressedDataUrl);
                    } else {
                        setTimeout(attemptCompress, 0);
                    }
                };

                attemptCompress();
            };

            img.onerror = (e) => {
                reject(new Error('Error loading image for compression'));
            };

            img.src = dataUrl;
        });
    };

    // Handle taking a picture for a specific dimension
    const takePicture = async (index: number) => {
        try {
            // Create a temporary file input
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'image/*'
            fileInput.capture = 'environment' // Use camera on mobile devices

            // Trigger the file selection
            fileInput.click()

            // Handle file selection
            fileInput.onchange = async (e: Event) => {
                const target = e.target as HTMLInputElement
                if (target && target.files && target.files.length > 0) {
                    const file = target.files[0]
                    const reader = new FileReader()

                    reader.onload = async (event) => {
                        if (event.target && event.target.result) {
                            try {
                                // Get the base64 string
                                const base64String = event.target.result as string

                                // Compress the image
                                const compressedImage = await compressImage(base64String);

                                // Update the dimension with the compressed image
                                const dimensionsCopy = [...dimensions]
                                dimensionsCopy[index] = {
                                    ...dimensionsCopy[index],
                                    image: compressedImage
                                }
                                setDimensions(dimensionsCopy)

                                // Explicitly save the image to Firebase when added
                                try {
                                    const db = firebase.firestore();

                                    if (currentEstimateId) {
                                        // Update existing estimate with the new image
                                        await db.collection('estimates').doc(currentEstimateId).update({
                                            dimensions: dimensionsCopy,
                                            dateModified: firebase.firestore.Timestamp.now()
                                        });
                                        console.log(`Successfully updated estimate ${currentEstimateId} after adding image`);

                                        // Update last saved timestamp
                                        setLastSaved(new Date());
                                    } else {
                                        // If no estimate exists yet, we need to create one
                                        console.log("No current estimate ID - creating new estimate with image");

                                        const estimateData = {
                                            customerId: customer.id,
                                            customerName: customer.name || "Untitled Estimate",
                                            dimensions: dimensionsCopy,
                                            solarScreens: solarScreens,
                                            meshColor: meshColor,
                                            frameColor: frameColor,
                                            discount: discount,
                                            notes: notes,
                                            costPerSquareFoot: costPerSquareFoot,
                                            dateCreated: firebase.firestore.Timestamp.now(),
                                            dateModified: firebase.firestore.Timestamp.now(),
                                            totalPrice: solarScreens.reduce((sum, screen) => sum + screen.price, 0) * (1 - (discount / 100)),
                                            status: 'draft',
                                            selectedServices: selectedServices
                                        };

                                        // Create new estimate with the image
                                        const newEstimateRef = await db.collection('estimates').add(removeUndefinedFields(estimateData));
                                        const newId = newEstimateRef.id;
                                        console.log(`Created new estimate with ID: ${newId} including image`);

                                        // Set the current ID to this new estimate
                                        setCurrentEstimateId(newId);

                                        // Update last saved timestamp
                                        setLastSaved(new Date());
                                    }
                                } catch (error) {
                                    console.error("Error saving image:", error);
                                }
                            } catch (error) {
                                console.error('Error compressing image:', error);
                            }
                        }
                    }

                    // Read the file as a data URL (base64)
                    reader.readAsDataURL(file)
                }
            }
        } catch (error) {
            console.error('Error taking picture:', error)
        }
    }

    // View image in larger modal
    const viewImage = (image: string, index: number) => {
        setSelectedImage(image)
        setSelectedDimensionIndex(index)
        setShowImageModal(true)
    }

    // Delete image from a dimension
    const deleteImage = async (index: number) => {
        const dimensionsCopy = [...dimensions]
        dimensionsCopy[index] = {
            ...dimensionsCopy[index],
            image: undefined
        }
        setDimensions(dimensionsCopy)

        // Close modal if we're deleting the currently viewed image
        if (selectedDimensionIndex === index) {
            setShowImageModal(false)
        }

        // Explicitly save the estimate when an image is deleted
        // We'll manually save to ensure the image deletion is persisted
        try {
            const db = firebase.firestore();

            if (currentEstimateId) {
                // Update existing estimate to remove the image
                await db.collection('estimates').doc(currentEstimateId).update({
                    dimensions: dimensionsCopy,
                    dateModified: firebase.firestore.Timestamp.now()
                });
                console.log(`Successfully updated estimate ${currentEstimateId} after image deletion`);
            }
        } catch (error) {
            console.error("Error saving after image deletion:", error);
        }
    }

    // Load all estimates from Firebase
    const loadEstimates = async () => {
        try {
            const db = firebase.firestore();
            const loadedEstimates: Estimate[] = [];

            // First try to get draft estimates
            try {
                const draftSnapshot = await db.collection('estimates')
                    .where('status', '==', 'draft')
                    .orderBy('dateModified', 'desc')
                    .get();

                // Add draft estimates first
                draftSnapshot.forEach(doc => {
                    loadedEstimates.push({
                        id: doc.id,
                        ...doc.data()
                    } as Estimate);
                });
            } catch (e) {
                console.error("Error loading draft estimates:", e);
                // Continue with other estimates even if this fails
            }

            // Get all estimates that either have status != 'draft' OR don't have a status field
            // For older estimates without a status field, we need to get them too
            const allEstimatesSnapshot = await db.collection('estimates')
                .orderBy('dateModified', 'desc')
                .get();

            // Add only non-draft estimates that haven't been added yet
            allEstimatesSnapshot.forEach(doc => {
                const data = doc.data();
                // Skip if already added (draft estimates) or if status is 'draft'
                if (loadedEstimates.some(e => e.id === doc.id) || data.status === 'draft') {
                    return;
                }

                loadedEstimates.push({
                    id: doc.id,
                    ...data
                } as Estimate);
            });

            setEstimates(loadedEstimates);
            setFilteredEstimates(loadedEstimates);
        } catch (error) {
            console.error("Error loading estimates:", error);
        }
    }

    // Remove undefined fields from estimateData
    const removeUndefinedFields = (obj: any): any => {
        if (Array.isArray(obj)) {
            return obj.map(removeUndefinedFields);
        } else if (obj && typeof obj === 'object') {
            return Object.fromEntries(
                Object.entries(obj)
                    .filter(([_, value]) => value !== undefined)
                    .map(([key, value]) => [key, removeUndefinedFields(value)])
            );
        }
        return obj;
    };

    // Auto-save the current estimate
    const autoSaveEstimate = async () => {
        try {
            // Don't auto-save if we don't have meaningful data
            if (!(dimensions.some(d => (d.length.whole !== undefined && d.length.whole !== null && d.length.whole > 0) ||
                (d.width.whole !== undefined && d.width.whole !== null && d.width.whole > 0)) ||
                customer.name ||
                solarScreens.length > 0)) {
                return;
            }

            const db = firebase.firestore();

            // Calculate total price from solar screens
            const totalPrice = solarScreens.reduce((sum, screen) => sum + screen.price, 0) * (1 - (discount / 100));

            // Only auto-save client data if we have a name
            let clientId = customer.id;

            // If we have customer information but no ID, create/update a client record
            if (customer.name && !clientId) {
                try {
                    // Parse name into first/last
                    let firstName = customer.name;
                    let lastName = "";

                    if (customer.name.includes(" ")) {
                        const nameParts = customer.name.split(" ");
                        firstName = nameParts[0];
                        lastName = nameParts.slice(1).join(" ");
                    }

                    // Create a new client record
                    const newClientId = uuidv4();
                    console.log(`Creating new client with ID: ${newClientId}`);

                    const clientData = {
                        firstName: firstName,
                        lastName: lastName,
                        address: customer.address || '',
                        email: customer.email || '',
                        phoneNumber: customer.phone || '',
                        id: newClientId
                    };

                    // Save the client
                    await db.collection('clients').doc(newClientId).set(clientData);

                    // Update our local state with the new ID
                    clientId = newClientId;
                    setCustomer({
                        ...customer,
                        id: newClientId
                    });

                    console.log(`Successfully created new client with ID: ${newClientId}`);
                } catch (error) {
                    console.error("Error creating client during auto-save:", error);
                    // Continue with the save anyway
                }
            }

            // Strip out image data from dimensions to avoid saving in auto-save
            // Images will only be saved when explicitly added or deleted
            const dimensionsWithoutImages = dimensions.map(dim => ({
                ...dim,
                image: undefined // Remove image data for auto-save
            }));

            // Create estimate object - always use customerId if available
            let estimateData: Omit<Estimate, 'id'> = {
                customerId: clientId, // Use the client ID (may be null/undefined)
                customerName: customer.name || "Untitled Estimate", // Keep name as fallback
                dimensions: dimensionsWithoutImages, // Use dimensions without image data for auto-save
                solarScreens: solarScreens.map(screen => ({
                    ...screen,
                    dimension: {
                        ...screen.dimension,
                        image: undefined // Remove image data from solarScreens for auto-save
                    }
                })),
                meshColor: meshColor,
                frameColor: frameColor,
                discount: discount,
                notes: notes,
                costPerSquareFoot: costPerSquareFoot,
                dateModified: firebase.firestore.Timestamp.now(),
                totalPrice: totalPrice,
                status: 'draft', // Mark all auto-saved estimates as drafts
                selectedServices: selectedServices, // Save the selected services
                dateCreated: currentEstimateId ? undefined : firebase.firestore.Timestamp.now()
            };

            // Use the function to clean `estimateData`
            estimateData = removeUndefinedFields(estimateData) as Omit<Estimate, 'id'>;

            // Store the current ID to check if it changed
            const previousEstimateId = currentEstimateId;

            // If we have an existing estimate, update it
            if (currentEstimateId) {
                console.log(`Updating existing estimate with ID: ${currentEstimateId}`);
                console.log(`Estimate has ${estimateData.dimensions.length} dimensions and ${estimateData.solarScreens.length} screens`);

                await db.collection('estimates').doc(currentEstimateId).update({
                    ...estimateData,
                    dateModified: firebase.firestore.Timestamp.now()
                });

                console.log(`Successfully updated estimate ${currentEstimateId}`);
            } else {
                // Create a new estimate
                console.log("Creating new estimate");
                console.log(`New estimate has ${estimateData.dimensions.length} dimensions and ${estimateData.solarScreens.length} screens`);

                const newEstimateRef = await db.collection('estimates').add({
                    ...estimateData,
                    dateCreated: firebase.firestore.Timestamp.now(),
                    dateModified: firebase.firestore.Timestamp.now()
                });

                // We need to set this here for future auto-saves
                const newId = newEstimateRef.id;
                console.log(`Created new estimate with ID: ${newId}`);
                setCurrentEstimateId(newId);
            }

            // Update last saved timestamp
            setLastSaved(new Date());

            // Refresh the list of estimates, but only if we're not loading an estimate
            if (previousEstimateId === currentEstimateId) {
                console.log('Estimate ID unchanged during save, refreshing estimates list');
                loadEstimates();
            } else {
                console.log(`Estimate ID changed during save from ${previousEstimateId || 'none'} to ${currentEstimateId}`);
            }
        } catch (error: any) {
            console.error("Error auto-saving estimate:", error);
            console.error("Error details:", error.message);
        }
    }

    // Load a specific estimate
    const loadEstimate = async (estimateId: string) => {
        try {
            console.log(`Starting to load estimate with ID: ${estimateId}`);
            const oldEstimateId = currentEstimateId;
            if (oldEstimateId) {
                console.log(`Current estimate ID before loading: ${oldEstimateId}`);
            }

            // Important: Update the current estimate ID FIRST
            // This ensures any auto-saves that happen will update this estimate
            // rather than creating a new one
            setCurrentEstimateId(estimateId);
            console.log(`Set current estimate ID to: ${estimateId}`);

            const db = firebase.firestore();
            const doc = await db.collection('estimates').doc(estimateId).get();

            if (doc.exists) {
                const estimateData = doc.data() as Estimate;
                console.log(`Loaded estimate data for ID: ${estimateId}`);
                console.log(`Estimate has ${estimateData.dimensions?.length || 0} dimensions and ${estimateData.solarScreens?.length || 0} screens`);

                // Update all state with loaded data - make sure we have at least one row
                setDimensions(estimateData.dimensions && estimateData.dimensions.length > 0 ? estimateData.dimensions : addRows());
                setCostPerSquareFoot(estimateData.costPerSquareFoot || 14.0);

                // Check if solar screens data exists
                if (estimateData.solarScreens && estimateData.solarScreens.length > 0) {
                    console.log(`Setting ${estimateData.solarScreens.length} solar screens from loaded estimate`);
                    setSolarScreens(estimateData.solarScreens || []);
                } else {
                    console.log(`No solar screens found in the estimate, will calculate them later`);
                    // If no solarScreens exist in the estimate but dimensions exist,
                    // we need to calculate the screens based on the dimensions
                    // We'll do this after setting all the state
                }

                setMeshColor(estimateData.meshColor || '');
                setFrameColor(estimateData.frameColor || '');
                setDiscount(estimateData.discount || 0);
                setNotes(estimateData.notes || '');

                // Restore selected services if they exist
                if (estimateData.selectedServices) {
                    console.log(`Setting ${Object.keys(estimateData.selectedServices).length} selected services from loaded estimate`);
                    setSelectedServices(estimateData.selectedServices);
                } else {
                    console.log(`No selected services found in the estimate`);
                    // Clear selected services if none were saved
                    setSelectedServices({});
                }

                // Set customer info
                if (estimateData.customerId) {
                    // Try to load the customer
                    const customerDoc = await db.collection('clients').doc(estimateData.customerId).get();
                    if (customerDoc.exists) {
                        const customerData = customerDoc.data() as PayingClient;
                        setCustomer({
                            name: `${customerData.firstName} ${customerData.lastName || ''}`,
                            address: customerData.address || '',
                            phone: customerData.phoneNumber || '',
                            email: customerData.email || '',
                            id: customerData.id
                        });
                        console.log(`Set customer data from client ID: ${customerData.id}`);
                    } else {
                        // Customer was deleted, just use the name from the estimate
                        setCustomer({
                            name: estimateData.customerName || '',
                            address: '',
                            phone: '',
                            email: '',
                        });
                        console.log(`Customer ID ${estimateData.customerId} not found, using estimate customer name`);
                    }
                } else {
                    // No customer ID but we have a name, create a temporary customer
                    // This is a fallback for older estimates without client reference
                    const tempCustomer = {
                        name: estimateData.customerName || '',
                        address: '',
                        phone: '',
                        email: '',
                    };
                    setCustomer(tempCustomer);
                    console.log(`No customer ID in estimate, using temporary customer with name: ${tempCustomer.name}`);

                    // Consider creating a proper client record if we have enough information
                    if (estimateData.customerName) {
                        console.log("Consider creating a proper client record for this customer");
                    }
                }

                // Verify current ID is still set correctly after async operations
                console.log(`Current estimate ID after loading customer: ${currentEstimateId}`);
                if (currentEstimateId !== estimateId) {
                    console.warn(`ID mismatch detected - setting current estimate ID to ${estimateId} again`);
                    setCurrentEstimateId(estimateId);
                }

                // After loading the estimate, ensure the screen dimensions UI reflects the loaded data
                // This forces a UI refresh so the input fields show the correct values
                const dimensionsCopy = estimateData.dimensions && estimateData.dimensions.length > 0
                    ? [...estimateData.dimensions]
                    : addRows(); // Add at least one row if there are no dimensions

                // Set dimensions directly instead of clearing first
                console.log(`Setting dimensions directly with ${dimensionsCopy.length} items`);
                setDimensions(dimensionsCopy);
                setTimeout(() => {
                    // Double-check that the current estimate ID is still the same one we're loading
                    // This helps prevent race conditions where we might have multiple loads happening
                    console.log(`Inside setTimeout - current estimate ID: ${currentEstimateId}, target: ${estimateId}`);
                    if (currentEstimateId !== estimateId) {
                        console.warn(`Estimate ID changed during loading: was ${estimateId}, now ${currentEstimateId}`);
                        // Reset ID if it was changed
                        console.log(`Re-setting current estimate ID to: ${estimateId}`);
                        setCurrentEstimateId(estimateId);
                        return;
                    }

                    // Verify dimensions are set correctly
                    console.log(`Dimensions check: current count = ${dimensions.length}, expected = ${dimensionsCopy.length}`);
                    if (dimensions.length === 0 || dimensions.length !== dimensionsCopy.length) {
                        console.log(`Dimensions need to be restored, setting ${dimensionsCopy.length} dimensions`);
                        setDimensions(dimensionsCopy);
                    }

                    // If we have dimensions, ensure we have screens
                    if (dimensionsCopy.length > 0 &&
                        dimensionsCopy.some(d => (d.length.whole ?? 0) > 0 && (d.width.whole ?? 0) > 0)) {

                        if (estimateData.solarScreens && estimateData.solarScreens.length > 0) {
                            console.log(`Using existing solar screens from estimate`);
                            // If we have screens and selectedServices, we need to preserve them
                            // Store the current selectedServices with their respective dimensions
                            const servicesWithDimensions = new Map();

                            if (estimateData.selectedServices) {
                                Object.keys(estimateData.selectedServices).forEach(screenId => {
                                    const screen = estimateData.solarScreens.find(s => s.id === screenId);
                                    if (screen) {
                                        // Create a key based on the dimension values
                                        const dimensionKey = `${screen.dimension.length.whole}_${screen.dimension.length.top || 0}_${screen.dimension.length.bottom || 0}_${screen.dimension.width.whole}_${screen.dimension.width.top || 0}_${screen.dimension.width.bottom || 0}`;

                                        let selectedServices = estimateData.selectedServices?.[screenId];
                                        if (selectedServices) {
                                            // Store the service with its dimension key
                                            servicesWithDimensions.set(dimensionKey, selectedServices);
                                        }
                                    }
                                });
                            }
                        } else {
                            // If no screens exist, simply calculate them
                            console.log(`No screens found, calculating screens from dimensions`);
                            calculateScreens();
                        }
                    }

                    // Final verification of estimate ID
                    console.log(`Final estimate ID check: ${currentEstimateId}`);
                }, 50);

                // Close the modal
                setShowEstimateModal(false);
                console.log(`Estimate ${estimateId} loaded successfully`);
            } else {
                console.error(`Estimate with ID ${estimateId} not found`);
            }
        } catch (error: any) {
            console.error("Error loading estimate:", error);
            console.error("Error details:", error.message);
        }
    }

    // Create a new estimate (clear the form)
    const createNewEstimate = () => {
        console.log('Creating new estimate - clearing all state');

        // Log the previous estimate ID if it exists
        if (currentEstimateId) {
            console.log(`Clearing previous estimate ID: ${currentEstimateId}`);
        }

        // Clear current estimate ID FIRST to prevent auto-saving to the previous estimate
        setCurrentEstimateId('');

        // Clear all state with empty values
        const emptyRows = addRows();
        console.log('Setting dimensions to empty rows:', JSON.stringify(emptyRows));
        setDimensions(emptyRows);
        setSolarScreens([]);
        setMeshColor('');
        setFrameColor('');
        setDiscount(0);
        setNotes('');
        setCostPerSquareFoot(14.0);
        setCustomer({
            name: '',
            email: '',
            phone: '',
            address: '',
            id: undefined
        });

        // Clear selected services
        setSelectedServices({});

        // Close the modal
        setShowEstimateModal(false);

        console.log('New estimate form ready');
    }

    // Delete an estimate
    const deleteEstimate = async (estimateId: string) => {
        try {
            console.log(`Attempting to delete estimate with ID: ${estimateId}`);

            const db = firebase.firestore();
            await db.collection('estimates').doc(estimateId).delete();
            console.log(`Successfully deleted estimate: ${estimateId}`);

            // Refresh the list of estimates
            loadEstimates();

            // If the deleted estimate was the current one, create a new estimate
            if (estimateId === currentEstimateId) {
                console.log(`Deleted the currently active estimate (${estimateId}), creating new estimate`);
                createNewEstimate();
            } else {
                console.log(`Deleted estimate ${estimateId} was not the active estimate (${currentEstimateId})`);
            }
        } catch (error: any) {
            console.error("Error deleting estimate:", error);
            console.error("Error details:", error.message);
        }
    }

    // Save estimate and client with proper references
    const saveEstimate = async () => {
        if (solarScreens.length === 0) {
            setError("Please calculate screen dimensions first.")
            return
        }

        if (customer.name == "") { setError("Please enter a customer name above."); return }
        if (customer.address == "") { setError("Please enter an customer address above."); return }
        if (customer.phone == "") { setError("Please enter a customer phone number above."); return }
        if (customer.email == "") { setError("Please enter an customer email above."); return }
        if (meshColor == "") { setError("Please select a mesh color above."); return }
        if (frameColor == "") { setError("Please select a frame color above."); return }

        console.log(`Saving estimate as final - current estimate ID: ${currentEstimateId || 'new'}`);

        let servicesProvided = solarScreens.map(screen => {
            return `${getDimensionString(screen.dimension)} - Solar Screen - $${(screen.price * (1 - (discount / 100))).toFixed(0)}`
        })

        let lineItems = solarScreens.map((screen) => {
            return {
                name: "Solar Screen",
                quantity: 1,
                unitPrice: screen.price * (1 - (discount / 100)),
                description: getDimensionString(screen.dimension) + " - Solar Screen"
            }
        })

        // 1. Save the client first and get its ID for the estimate
        // Either use existing client ID or create a new one
        const clientId = customer.id || uuidv4();

        // Split the name into first and last if possible
        let firstName = customer.name;
        let lastName = "";

        if (customer.name.includes(" ")) {
            const nameParts = customer.name.split(" ");
            firstName = nameParts[0];
            lastName = nameParts.slice(1).join(" ");
        }

        // Create client object
        let client: PayingClient = {
            firstName: firstName,
            lastName: lastName,
            address: customer.address,
            email: customer.email,
            phoneNumber: customer.phone,
            zipCode: "00000",
            discounts: [{
                name: "Solar Screen Discount",
                amount: discount
            }],
            dateOfService: new Date(),
            costOfService: parseInt(finalPrice()),
            servicesProvided: servicesProvided,
            id: clientId,
            services: solarScreens.map(screen => ({
                name: "Solar Screen",
                price: screen.price * (1 - (discount / 100)),
                id: screen.id,
                dimensions: screen.dimension,
                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
            })),
            lineItems: lineItems,
            misc: {
                meshColor: meshColor,
                frameColor: frameColor,
                discount: discount,
                estimateId: currentEstimateId // Store reference to the estimate
            },
            notes:
                `Here is your estimate for solar screens. Upon acceptance, 50% of the total cost will be due. The remaining 50% will be due upon completion of the job. Thank you for your business!

Notes: ${notes}

Screen Details:
Screen Mesh Color - ${meshColor}
Screen Frames Color - ${frameColor}

${discount != 0 ? `The price seen here reflects the discount of ${discount}%` : ""}`
        }

        console.log(`Saving client with ID: ${clientId}`);
        const db = firebase.firestore();
        await db.collection("clients").doc(clientId).set(client);
        console.log(`Successfully saved client with ID: ${clientId}`);

        // Update customer state with the ID for future reference
        setCustomer({
            ...customer,
            id: clientId
        });

        // 2. Now update or create the estimate with client reference
        // If we have an existing estimate, update it with the client ID
        if (currentEstimateId) {
            console.log(`Updating estimate ${currentEstimateId} with client ID ${clientId}`);
            await db.collection('estimates').doc(currentEstimateId).update({
                customerId: clientId,
                customerName: customer.name,
                dimensions: dimensions,
                solarScreens: solarScreens,
                meshColor: meshColor,
                frameColor: frameColor,
                discount: discount,
                notes: notes,
                costPerSquareFoot: costPerSquareFoot,
                dateModified: firebase.firestore.Timestamp.now(),
                totalPrice: solarScreens.reduce((sum, screen) => sum + screen.price, 0) * (1 - (discount / 100)),
                status: 'final', // Mark as final
                selectedServices: selectedServices
            });
            console.log(`Successfully updated estimate ${currentEstimateId} to final status with client ID ${clientId}`);
        } else {
            console.log(`No current estimate ID - creating new final estimate`);
            // Auto-save the current estimate as final before proceeding
            const db = firebase.firestore();

            // Calculate total price from solar screens
            const totalPrice = solarScreens.reduce((sum, screen) => sum + screen.price, 0) * (1 - (discount / 100));

            // Create estimate object with the client ID
            let estimateData = {
                customerId: clientId,
                customerName: customer.name || "Untitled Estimate",
                dimensions: dimensions,
                solarScreens: solarScreens,
                meshColor: meshColor,
                frameColor: frameColor,
                discount: discount,
                notes: notes,
                costPerSquareFoot: costPerSquareFoot,
                dateCreated: firebase.firestore.Timestamp.now(),
                dateModified: firebase.firestore.Timestamp.now(),
                totalPrice: totalPrice,
                status: 'final', // Mark as final
                selectedServices: selectedServices
            };

            // Clean the data
            estimateData = removeUndefinedFields(estimateData);

            // Create a new final estimate
            const newEstimateRef = await db.collection('estimates').add(estimateData);
            const newId = newEstimateRef.id;
            console.log(`Created new final estimate with ID: ${newId}`);

            // Set the current ID to this new estimate
            setCurrentEstimateId(newId);

            // Update the client with the new estimate ID reference
            await db.collection("clients").doc(clientId).update({
                "misc.estimateId": newId
            });
            console.log(`Updated client ${clientId} with estimate ID: ${newId}`);
        }

        // No longer needed because we moved this logic to the beginning of the function

        // Refresh estimates list to update UI
        loadEstimates();

        console.log(`Opening invoice creation page for client: ${clientId}`);
        window.open(`https://payment.definedcleaning.com/createInvoice/${clientId}`, "_blank")
    }

    const getDimensionSpan = (dimension: Dimension) => {
        return (
            <span>
                {getDimensionString(dimension)}
            </span>
        )
    }

    const getDimensionString = (dimension: Dimension) => {
        const lengthWhole = dimension.length.whole !== undefined && dimension.length.whole !== null ? dimension.length.whole : '';
        const widthWhole = dimension.width.whole !== undefined && dimension.width.whole !== null ? dimension.width.whole : '';

        return `${lengthWhole} ${dimension.length.top != undefined ? `${dimension.length.top}/${dimension.length.bottom}` : ""} X ${widthWhole} ${dimension.width.top != undefined ? `${dimension.width.top}/${dimension.width.bottom}` : ""}`
    }

    const finalPrice = () => {
        return (solarScreens.reduce((sum, screen) => sum + screen.price, 0) * (1 - (discount / 100))).toFixed(0)
    }

    return (
        <>
            <div style={{ backgroundColor: "white" }}>
                <Container style={{ backgroundColor: "white" }}>
                    {/* Estimate management section */}
                    <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                        <Row>
                            <Col xs="12">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">
                                        {currentEstimateId
                                            ? `Estimate #${currentEstimateId.substring(0, 8)}...`
                                            : "New Estimate"}
                                    </h5>
                                    <div>
                                        {lastSaved && (
                                            <small className="text-muted me-3">
                                                Last saved: {lastSaved.toLocaleTimeString()}
                                            </small>
                                        )}
                                        <MaterialButton
                                            variant="outlined"
                                            size="small"
                                            onClick={() => setShowEstimateModal(true)}
                                            className="me-2"
                                        >
                                            Find Estimate
                                        </MaterialButton>
                                        <MaterialButton
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={createNewEstimate}
                                        >
                                            New Estimate
                                        </MaterialButton>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* Estimates modal */}
                    <Modal
                        show={showEstimateModal}
                        onHide={() => setShowEstimateModal(false)}
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Find Estimate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-4">
                                <Form.Label>Search by customer name</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search estimates..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    {searchTerm && (
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => setSearchTerm('')}
                                        >
                                            Clear
                                        </Button>
                                    )}
                                </InputGroup>
                            </Form.Group>

                            {filteredEstimates.length === 0 ? (
                                <div className="text-center py-4">
                                    <p className="text-muted">No estimates found.</p>
                                </div>
                            ) : (
                                <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Table hover>
                                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                                            <tr>
                                                <th style={{ width: '45%' }}>Customer</th>
                                                <th style={{ width: '20%' }}>Date</th>
                                                <th style={{ width: '15%' }}>Status</th>
                                                <th style={{ width: '10%' }}>Price</th>
                                                <th style={{ width: '10%' }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredEstimates.map((estimate) => (
                                                <tr
                                                    key={estimate.id}
                                                    className={estimate.id === currentEstimateId ? 'table-primary' : ''}
                                                    onClick={() => loadEstimate(estimate.id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <td>
                                                        {estimate.customerName}
                                                    </td>
                                                    <td>
                                                        {estimate.dateModified?.toDate().toLocaleDateString() || 'Unknown'}
                                                    </td>
                                                    <td>
                                                        {estimate.status === 'draft' ? (
                                                            <Badge
                                                                variant="info"
                                                                className="me-1"
                                                                style={{ fontSize: '0.8rem', backgroundColor: '#0dcaf0' }}
                                                            >
                                                                Draft
                                                            </Badge>
                                                        ) : (
                                                            <Badge
                                                                variant="success"
                                                                className="me-1"
                                                                style={{ fontSize: '0.8rem', backgroundColor: '#198754' }}
                                                            >
                                                                Final
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td>${estimate.totalPrice?.toFixed(0) || 0}</td>
                                                    <td>
                                                        <Button
                                                            variant="link"
                                                            className="p-0 text-danger"
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent row click from triggering
                                                                if (window.confirm('Are you sure you want to delete this estimate?')) {
                                                                    deleteEstimate(estimate.id);
                                                                }
                                                            }}
                                                        >
                                                            <i className="material-icons">delete</i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEstimateModal(false)}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                        <Row>
                            <Col>
                                <h5>Screen Dimensions</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Make sure that you enter the dimensions the exact size that the screens are going to be built.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField variant='standard' label='Cost Per Square Foot' type='number' onChange={(event) => setCostPerSquareFoot(parseInt(event.target.value))} fullWidth={true} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs="6"><strong>Length</strong></Col>
                            <Col xs="6"><strong>Width</strong></Col>
                        </Row>
                        {
                            dimensions.map((dimension, index) => {
                                return (
                                    <div>
                                        {/* Length Whole Number */}
                                        <Row>
                                            <Col xs="4" md="2">
                                                <TextField
                                                    value={dimension.length.whole === null ? '' : dimension.length.whole}
                                                    variant='standard'
                                                    label='Length Whole'
                                                    onChange={(event) => {
                                                        const dimensionsCopy = [...dimensions];
                                                        dimensionsCopy[index] = {
                                                            ...dimensionsCopy[index],
                                                            length: {
                                                                ...dimensionsCopy[index].length,
                                                                whole: event.target.value === '' ? null : Number(event.target.value)
                                                            }
                                                        };
                                                        setDimensions(dimensionsCopy);
                                                        handleDimensionChange(dimensionsCopy, index);
                                                    }}
                                                    type="number" />
                                            </Col>


                                            {/* Length Fraction Top */}

                                            <Col xs="4" md="2">
                                                <TextField
                                                    variant='standard'
                                                    label='Length Fraction Top'
                                                    value={dimension.length.top === null || dimension.length.top === undefined ? '' : dimension.length.top}
                                                    onChange={(event) => {
                                                        const dimensionsCopy = [...dimensions];
                                                        dimensionsCopy[index] = {
                                                            ...dimensionsCopy[index],
                                                            length: {
                                                                ...dimensionsCopy[index].length,
                                                                top: event.target.value === '' ? null : Number(event.target.value)
                                                            }
                                                        };
                                                        setDimensions(dimensionsCopy);
                                                        handleDimensionChange(dimensionsCopy, index);
                                                    }}
                                                    type="number" />
                                            </Col>


                                            {/* Length Fraction Bottom */}

                                            <Col xs="4" md="2">
                                                <TextField
                                                    variant='standard'
                                                    label='Length Fraction Bottom'
                                                    value={dimension.length.bottom === null || dimension.length.bottom === undefined ? '' : dimension.length.bottom}
                                                    onChange={(event) => {
                                                        const dimensionsCopy = [...dimensions];
                                                        dimensionsCopy[index] = {
                                                            ...dimensionsCopy[index],
                                                            length: {
                                                                ...dimensionsCopy[index].length,
                                                                bottom: event.target.value === '' ? null : Number(event.target.value)
                                                            }
                                                        };
                                                        setDimensions(dimensionsCopy);
                                                        handleDimensionChange(dimensionsCopy, index);
                                                    }}
                                                    type="number" />

                                            </Col>

                                            <Col xs="4" md="2">
                                                <TextField
                                                    value={dimension.width.whole === null ? '' : dimension.width.whole}
                                                    variant='standard'
                                                    label='Width Whole'
                                                    onChange={(event) => {
                                                        const dimensionsCopy = [...dimensions];
                                                        dimensionsCopy[index] = {
                                                            ...dimensionsCopy[index],
                                                            width: {
                                                                ...dimensionsCopy[index].width,
                                                                whole: event.target.value === '' ? null : Number(event.target.value)
                                                            }
                                                        };
                                                        setDimensions(dimensionsCopy);
                                                        handleDimensionChange(dimensionsCopy, index);
                                                    }}
                                                    type="number" />
                                            </Col>


                                            {/* Width Fraction Top */}

                                            <Col xs="4" md="2">
                                                <TextField
                                                    variant='standard'
                                                    label='Width Fraction Top'
                                                    value={dimension.width.top === null || dimension.width.top === undefined ? '' : dimension.width.top}
                                                    onChange={(event) => {
                                                        const dimensionsCopy = [...dimensions];
                                                        dimensionsCopy[index] = {
                                                            ...dimensionsCopy[index],
                                                            width: {
                                                                ...dimensionsCopy[index].width,
                                                                top: event.target.value === '' ? null : Number(event.target.value)
                                                            }
                                                        };
                                                        setDimensions(dimensionsCopy);
                                                        handleDimensionChange(dimensionsCopy, index);
                                                    }}
                                                    type="number" />
                                            </Col>


                                            {/* Width Fraction Bottom */}

                                            <Col xs="4" md="2">
                                                <TextField
                                                    variant='standard'
                                                    label='Width Fraction Bottom'
                                                    value={dimension.width.bottom === null || dimension.width.bottom === undefined ? '' : dimension.width.bottom}
                                                    onChange={(event) => {
                                                        const dimensionsCopy = [...dimensions];
                                                        dimensionsCopy[index] = {
                                                            ...dimensionsCopy[index],
                                                            width: {
                                                                ...dimensionsCopy[index].width,
                                                                bottom: event.target.value === '' ? null : Number(event.target.value)
                                                            }
                                                        };
                                                        setDimensions(dimensionsCopy);
                                                        handleDimensionChange(dimensionsCopy, index);
                                                    }}
                                                    type="number" />
                                            </Col>
                                        </Row>

                                        {/* Image capture section */}
                                        <Row className="mt-3 mb-3">
                                            <Col xs="12" className="d-flex align-items-center">
                                                {dimension.image ? (
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            style={{
                                                                width: '60px',
                                                                height: '60px',
                                                                overflow: 'hidden',
                                                                cursor: 'pointer',
                                                                marginRight: '10px',
                                                                border: '1px solid #ddd',
                                                                borderRadius: '4px'
                                                            }}
                                                            onClick={() => viewImage(dimension.image as string, index)}
                                                        >
                                                            <img
                                                                src={dimension.image}
                                                                alt={`Dimension ${index + 1} image`}
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                            />
                                                        </div>
                                                        <MaterialButton
                                                            variant="outlined"
                                                            color="error"
                                                            size="small"
                                                            onClick={() => deleteImage(index)}
                                                            style={{ marginRight: '10px' }}
                                                        >
                                                            Delete
                                                        </MaterialButton>
                                                        <MaterialButton
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => takePicture(index)}
                                                        >
                                                            Replace Image
                                                        </MaterialButton>
                                                    </div>
                                                ) : (
                                                    <MaterialButton
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => takePicture(index)}
                                                        startIcon={<span className="material-icons">photo_camera</span>}
                                                    >
                                                        Take Picture
                                                    </MaterialButton>
                                                )}
                                            </Col>
                                        </Row>

                                    </div>
                                )
                            })
                        }
                        <Row>
                            <Col xs="12" md="4" className="my-3">
                                <MaterialButton fullWidth={true} variant='contained' onClick={addRow}>Add Row</MaterialButton>
                            </Col>
                            <Col xs="12" md="4" className="my-3">
                                <MaterialButton fullWidth={true} variant='contained' onClick={() => calculateScreens()}>Calculate</MaterialButton>
                            </Col>
                        </Row>

                    </div>

                    <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                        <Row>
                            <Col xs="12">
                                <h4>Customer Information</h4>
                            </Col>
                        </Row>
                        {/* Customer Full Name with Search */}
                        <Row>
                            <Col xs="12" className="mt-3 mb-3">
                                <Autocomplete
                                    id="customer-search"
                                    options={clients || []}
                                    getOptionLabel={(client) => {
                                        if (typeof client === "string") {
                                            return client
                                        }
                                        // Handle special case for "Add New Customer" option
                                        if (client.id === 'new-customer') {
                                            return `➕ Add "${client.firstName}" as new customer`;
                                        }
                                        return `${client.firstName} ${client.lastName || ""}`;
                                    }}
                                    noOptionsText="No customers found"
                                    freeSolo
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            label='Customer Name'
                                            onChange={(event) => {
                                                const searchTerm = event.target.value;
                                                // Update customer name as user types
                                                setCustomer({
                                                    ...customer,
                                                    name: searchTerm
                                                });
                                                if (searchTerm) {
                                                    getCustomers(searchTerm);
                                                }
                                            }}
                                            fullWidth={true}
                                            value={customer.name || ""}
                                        />
                                    }
                                    inputValue={customer.name || ""}
                                    onInputChange={(event, newInputValue) => {
                                        if (event) {
                                            setCustomer({
                                                ...customer,
                                                name: newInputValue
                                            });
                                        }
                                    }}
                                    onChange={(event, value) => {
                                        // Handle both string (free solo) and object values
                                        if (typeof value === 'string') {
                                            setCustomer({
                                                ...customer,
                                                name: value
                                            });
                                            return;
                                        }

                                        if (value && typeof value === 'object') {
                                            if (value.id === 'new-customer') {
                                                // Keep the typed text in the field
                                                // Don't change the current customer name that was set during typing
                                                setCustomer({
                                                    ...customer,
                                                    name: value.firstName
                                                })
                                                return;
                                            }
                                            // Fill in customer details from selected client
                                            setCustomer({
                                                name: `${value.firstName} ${value.lastName || ""}`,
                                                address: value.address || "",
                                                phone: value.phoneNumber || "",
                                                email: value.email || ""
                                            });
                                        }
                                    }}
                                    filterOptions={(options, state) => {
                                        const filtered = options.filter(option => {
                                            const fullName = `${option.firstName} ${option.lastName || ""}`.toLowerCase();
                                            return fullName.includes(state.inputValue.toLowerCase());
                                        });

                                        // Add "Add New Customer" option if there are no matches or if there is input
                                        if ((filtered.length === 0 || state.inputValue) && state.inputValue.length > 0) {
                                            filtered.push({
                                                id: 'new-customer',
                                                firstName: state.inputValue,
                                                lastName: '',
                                            } as PayingClient);
                                        }

                                        return filtered;
                                    }}
                                />
                            </Col>

                            <Col xs="12">
                                <TextField
                                    variant='standard'
                                    label='Address'
                                    value={customer.address}
                                    onChange={(event) => {
                                        setCustomer({
                                            ...customer,
                                            address: event.target.value
                                        })
                                    }}
                                    fullWidth={true}
                                    type="text" />
                            </Col>

                            <Col xs="6">
                                <TextField
                                    variant='standard'
                                    label='Phone Number'
                                    value={customer.phone}
                                    onChange={(event) => {
                                        setCustomer({
                                            ...customer,
                                            phone: event.target.value
                                        })
                                    }}
                                    fullWidth={true}
                                    type="text" />
                            </Col>

                            <Col xs="6">
                                <TextField
                                    variant='standard'
                                    label='Email'
                                    value={customer.email}
                                    onChange={(event) => {
                                        setCustomer({
                                            ...customer,
                                            email: event.target.value
                                        })
                                    }}
                                    fullWidth={true}
                                    type="text" />
                            </Col>
                        </Row>
                    </div>

                    <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                        <h1>Job Details</h1>
                        <Row className="my-3">
                            <Col xs="12">
                                <strong>Estimated Time To Finish (Man Hours)</strong>
                            </Col>
                            <Col xs="12">
                                {`${(solarScreens.length * 45) / 60} Hours`}
                            </Col>
                        </Row>
                        {
                            solarScreens && solarScreens.map((screen, index) => {
                                return (
                                    <div>
                                        {
                                            selectedServices && selectedServices[screen.id] !== undefined &&
                                            <Alert severity='success' className="mb-3">
                                                Selected - <strong>{getDimensionSpan(screen.dimension)} Solar Screen</strong>
                                            </Alert>
                                        }
                                        {
                                            !selectedServices[screen.id] &&
                                            <Accordion className="mb-5" key={index}>
                                                <Card>
                                                    <Card.Header className="mb-2">
                                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                                            <p>
                                                                <strong>{getDimensionSpan(screen.dimension)} - NOT ARCHED Solar Screen</strong>
                                                            </p>
                                                            <p>
                                                                Select this if the window is a rectangle and has no arch. If the window has an arch, select the ARCHED Solar Screen option.
                                                            </p>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <Row className="my-2">
                                                                <Col xs="12">

                                                                    <div style={{ fontSize: "18px" }} className="d-flex align-items-center">
                                                                        <strong>
                                                                            {getDimensionSpan(screen.dimension)} NOT ARCHED Solar Screen
                                                                        </strong>

                                                                        {screen.dimension.image && (
                                                                            <div
                                                                                className="ms-3"
                                                                                style={{
                                                                                    width: '40px',
                                                                                    height: '40px',
                                                                                    overflow: 'hidden',
                                                                                    cursor: 'pointer',
                                                                                    border: '1px solid #ddd',
                                                                                    borderRadius: '4px'
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSelectedImage(screen.dimension.image as string);
                                                                                    setSelectedDimensionIndex(-1); // Not tied to a specific dimension index
                                                                                    setShowImageModal(true);
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={screen.dimension.image}
                                                                                    alt="Screen thumbnail"
                                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>


                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 80 Screen Mesh",
                                                                                price: screen.price,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 80 Screen Mesh: <strong>${screen.price.toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        {/* The cost of a grid is basically 6.25 a square foot because those full grids cost a lot more money and they take significantly more time. That's why we divide by .6 */}
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 80 Screen Mesh and a Grid",
                                                                                price: screen.price / .69,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 80 Screen Mesh and a Grid: <strong> ${(screen.price / .69).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "Rescreen - 80 Screen Mesh",
                                                                                price: screen.rescreenPrice,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                            })
                                                                        }}>
                                                                            Rescreen - 80 Screen Mesh: <strong>${(screen.rescreenPrice).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 90 Screen Mesh",
                                                                                price: screen.price / .85,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 90 Screen Mesh: <strong>${(screen.price / .85).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 90 Screen Mesh and a Grid",
                                                                                price: (screen.price / .85) / .69,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 90 Screen Mesh and a Grid: <strong>${(((screen.price / .85) / .69)).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "Rescreen - 90 Screen Mesh",
                                                                                price: screen.rescreenPrice * 1.2,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                            })
                                                                        }}>
                                                                            Rescreen - 90 Screen Mesh: <strong>${((screen.rescreenPrice ?? undefined) * 1.2).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "Screen Door",
                                                                                price: screen.price / .65,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SCREEN_DOOR
                                                                            })
                                                                        }}>
                                                                            Screen Door - 80 Screen Mesh: <strong>${((screen.price ?? undefined) / .65).toFixed(0)}</strong>
                                                                        </MaterialButton>

                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "Screen Door",
                                                                                price: screen.price / .65,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SCREEN_DOOR
                                                                            })
                                                                        }}>
                                                                            Screen Door - 90 Screen Mesh: <strong>${((screen.price * 1.2 ?? undefined) / .65).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    {
                                                                        screen.actualPrice && screen.actualPrice <= 100 &&
                                                                        <div>
                                                                            <strong>Screen Actual Cost:</strong> ${screen.actualPrice.toFixed(0)}
                                                                        </div>
                                                                    }
                                                                    <hr style={{ color: "black", height: .5 }} />
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>

                                                    {/* Arched Screens */}
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                                            <div style={{ fontSize: "18px" }}>
                                                                <p>
                                                                    <strong>{getDimensionSpan(screen.dimension)} - ARCHED Solar Screen</strong>
                                                                </p>
                                                                <p>
                                                                    Select this if the window contains an arch or is a circle.
                                                                </p>
                                                            </div>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <Row className="my-2">
                                                                <Col xs="12">

                                                                    <div style={{ fontSize: "18px" }} className="d-flex align-items-center">
                                                                        <strong>
                                                                            {getDimensionSpan(screen.dimension)} ARCHED Solar Screen
                                                                        </strong>

                                                                        {screen.dimension.image && (
                                                                            <div
                                                                                className="ms-3"
                                                                                style={{
                                                                                    width: '40px',
                                                                                    height: '40px',
                                                                                    overflow: 'hidden',
                                                                                    cursor: 'pointer',
                                                                                    border: '1px solid #ddd',
                                                                                    borderRadius: '4px'
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSelectedImage(screen.dimension.image as string);
                                                                                    setSelectedDimensionIndex(-1); // Not tied to a specific dimension index
                                                                                    setShowImageModal(true);
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={screen.dimension.image}
                                                                                    alt="Screen thumbnail"
                                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 80 Screen Mesh",
                                                                                price: screen.archedPrice,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 80 Screen Mesh: <strong>${screen.archedPrice.toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        {/* The cost of a grid is basically 6.25 a square foot because those full grids cost a lot more money and they take significantly more time. That's why we divide by .6 */}
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 80 Screen Mesh and a Grid",
                                                                                price: screen.archedPrice / .75,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 80 Screen Mesh and a Grid: <strong> ${(screen.archedPrice / .75).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "Rescreen - 80 Screen Mesh",
                                                                                price: screen.rescreenPrice * 1.2,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                            })
                                                                        }}>
                                                                            Rescreen - 80 Screen Mesh: <strong>${(screen.rescreenPrice * 1.2).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 90 Screen Mesh",
                                                                                price: screen.archedPrice / .85,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 90 Screen Mesh: <strong>${(screen.archedPrice / .85).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "New Build - 90 Screen Mesh and a Grid",
                                                                                price: (screen.archedPrice / .85) / .75,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                            })
                                                                        }}>
                                                                            New Build - 90 Screen Mesh and a Grid: <strong>${(((screen.archedPrice / .85) / .75)).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    <div>
                                                                        <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                            addSelectedService({
                                                                                name: "Rescreen - 90 Screen Mesh",
                                                                                price: screen.rescreenPrice * 1.4,
                                                                                id: screen.id,
                                                                                dimensions: screen.dimension,
                                                                                type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                            })
                                                                        }}>
                                                                            Rescreen - 90 Screen Mesh: <strong>${((screen.rescreenPrice ?? undefined) * 1.4).toFixed(0)}</strong>
                                                                        </MaterialButton>
                                                                    </div>
                                                                    {
                                                                        screen.actualPrice && screen.actualPrice <= 100 &&
                                                                        <div>
                                                                            <strong>Screen Actual Cost:</strong> ${screen.actualPrice.toFixed(0)}
                                                                        </div>
                                                                    }
                                                                    <hr style={{ color: "black", height: .5 }} />
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </Container>
            </div >

            {/* The selected services */}
            {
                solarScreens.length > 0 &&
                <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                    {
                        solarScreens.length > 0 &&
                        <Row>
                            <Col xs="12" md="6">
                                <Container>
                                    <Row>
                                        <Col xs="12">
                                            <Image src="/solar-screen-colors-scaled.jpg" width={300} fluid />
                                        </Col>
                                        <Col xs="12" className="mt-4">
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label"><strong>What color for the screen mesh?</strong></FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                >
                                                    {
                                                        meshColors.map((color, index) => {
                                                            return <FormControlLabel value={color} control={<Radio />} label={color} onChange={() => setMeshColor(color)} />
                                                        })
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xs="12" md="6">
                                <Container>
                                    <Row>
                                        <hr />
                                        <Col xs="12">
                                            <Image src="/screenframecolors-scaled.jpg" width={350} fluid />
                                        </Col>
                                        <Col xs="12" className="mt-3">
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label"><strong>What color for the screen frames?</strong></FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                >
                                                    {
                                                        frameColors.map((color, index) => {
                                                            return <FormControlLabel value={color} control={<Radio />} label={color} onChange={() => setFrameColor(color)} />
                                                        })
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>




                        </Row>
                    }
                </div>
            }


            <div className='p-4 my-5' style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                {
                    Object.keys(selectedServices).map(serviceId => {
                        let service: SelectedService = selectedServices[serviceId]

                        return (
                            <Row className="my-3">
                                <Col xs="6">
                                    <strong>{getDimensionSpan(service.dimensions)} - {service.name}</strong>
                                </Col>
                                <Col xs="4">
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            ${service.price.toFixed(0)}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="2" xs="12">
                                    <div className="d-flex align-middle justify-content-end">
                                        <div>
                                            <MaterialButton color="error" variant='contained' onClick={() => {
                                                delete selectedServices[serviceId]
                                                setSelectedServices({ ...selectedServices })
                                            }}>
                                                X
                                            </MaterialButton>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                }

                {/* Display the color that was selected for the screen mesh */}
                {
                    meshColor &&
                    <Row>
                        <Col>
                            <strong>Screen Mesh Color: {meshColor}</strong>
                        </Col>
                    </Row>
                }
                {/* Display the color that was selected for the screen frame */}
                {
                    frameColor &&
                    <Row>
                        <Col>
                            <strong>Screen Frame Color: {frameColor}</strong>
                        </Col>
                    </Row>
                }
                <hr />
                <Row>
                    <Col xs="6">
                        <div>
                            <div>
                                <strong>Subtotal:</strong>
                            </div>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="d-flex justify-content-end">
                            <div>
                                <strong>${(Object.values(selectedServices).reduce((sum, service) => sum + service.price, 0) * (1 - (discount / 100))).toFixed(0)}</strong>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" xs="12" className="my-1">
                        <div>
                            <strong>Discount: {discount}%</strong>
                        </div>
                    </Col>
                </Row>
                <Row className="my-3">

                    <Col md="1" xs="12" className="my-1">
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(0)
                            }}>
                                0%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className="my-1">
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(5)
                            }}>
                                5%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className="my-1">
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(10)
                            }}>
                                10%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(15)
                            }}>
                                15%
                            </MaterialButton>
                        </div>
                    </Col>

                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(20)
                            }}>
                                20%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(25)
                            }}>
                                25%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(30)
                            }}>
                                30%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(35)
                            }}>
                                35%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(40)
                            }}>
                                40%
                            </MaterialButton>
                        </div>
                    </Col>
                    <Col md="1" xs="12" className='my-1'>
                        <div >
                            <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                setDiscount(45)
                            }}>
                                45%
                            </MaterialButton>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>

                        </div>
                    </Col>
                </Row>
                {
                    error &&
                    <Row>
                        <Col>
                            <div>
                                <Alert severity='error' >{error}</Alert>
                            </div>
                        </Col>
                    </Row>
                }
                <Row className="my-3">
                    <Col>
                        <TextField label="Notes" variant="outlined" multiline={true} rows={5} fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs="12">
                        <Alert severity="warning">Be absolutely sure that everything that you entered above is correct. If you've entered information incorrectly and we order the wrong thing that's a huge problem.</Alert>
                    </Col>
                    <Col xs="12" md="3">
                        <MaterialButton fullWidth={true} className="my-3" variant='contained' onClick={() => { saveEstimate() }}>
                            Save Estimate
                        </MaterialButton>
                    </Col>
                </Row>
            </div>

            {/* Image Preview Modal */}
            <Modal
                show={showImageModal}
                onHide={() => setShowImageModal(false)}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center p-3">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Screen dimension"
                            style={{ maxWidth: '100%', maxHeight: '70vh' }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowImageModal(false)}>
                        Close
                    </Button>
                    {selectedDimensionIndex >= 0 && (
                        <Button variant="danger" onClick={() => {
                            deleteImage(selectedDimensionIndex);
                            setShowImageModal(false);
                        }}>
                            Delete Image
                        </Button>
                    )}
                    {selectedDimensionIndex >= 0 && (
                        <Button variant="primary" onClick={() => {
                            takePicture(selectedDimensionIndex);
                            setShowImageModal(false);
                        }}>
                            Replace Image
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default Screens