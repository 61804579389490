import firebase from 'firebase'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row, Button, Container, Table, Alert, Toast, Form, InputGroup, Pagination, Nav, Modal } from 'react-bootstrap'
import { Button as MaterialButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import Customer from '../components/customer'
import CONSTANTS from '../constants'
import { Message, PayingClient, SurveyObject } from '../models/models'
import MessageService from '../services/messageService'
import './crm.css'
import SideBar from '../components/sidebar'

const CRM = () => {

    const [showCustomerDiaolog, setShowCustomerDialog] = useState(false)

    /// The customers that we already have saved to the system
    const [customers, setCustomers] = useState<PayingClient[]>([])
    const [filteredCustomers, setFilteredCustomers] = useState<PayingClient[]>([])
    const [searchTerm, setSearchTerm] = useState<string>("")

    // Pagination for customers
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [customersPerPage] = useState<number>(20)

    /// The customer that has been selected
    const [selectedCustomer, setSelectedCustomer] = useState<PayingClient>()

    const [surveys, setSurveys] = useState<SurveyObject[]>()
    const [filteredSurveys, setFilteredSurveys] = useState<SurveyObject[]>([])
    const [surveySearchTerm, setSurveySearchTerm] = useState<string>("")

    // Pagination for surveys
    const [currentSurveyPage, setCurrentSurveyPage] = useState<number>(1)
    const [surveysPerPage] = useState<number>(20)

    const [messagesAlreadySent, setMessagesAlreadySent] = useState<Message[]>()

    // Track expanded rows
    const [expandedCustomerRows, setExpandedCustomerRows] = useState<{ [key: number]: boolean }>({})
    const [expandedSurveyRows, setExpandedSurveyRows] = useState<{ [key: number]: boolean }>({})

    // Navigation between customers and surveys
    const [activeTab, setActiveTab] = useState<'customers' | 'surveys'>('customers')

    // Services modal state
    const [showServicesModal, setShowServicesModal] = useState(false)
    const [selectedServices, setSelectedServices] = useState<string[]>([])
    const [selectedCustomerName, setSelectedCustomerName] = useState('')

    /// Any errors that happen on the page
    const [error, setError] = useState<string>()

    /// Any notifications that need to be shown to the user
    const [notification, setNotification] = useState<string>()

    const createNewCustomer = () => {
        setSelectedCustomer(undefined)
        setShowCustomerDialog(true)
    }

    useEffect(() => {

        if (firebase.apps.length == 0) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }

        const db = firebase.firestore()

        db.collection(CONSTANTS.FIRESTORE.SURVEYS).get().then(result => {
            let documents = result.docs.map(doc => doc.data())

            let surveys = documents as SurveyObject[]
            setSurveys(surveys)
            setFilteredSurveys(surveys)
        })

        db.collection('clients').orderBy(CONSTANTS.FIRESTORE.DATE_OF_SERVICE, "desc").onSnapshot(result => {
            let documents = result.docs.filter(doc => doc.data()["archived"] != true).map(doc => doc.data())

            let payingClients = documents as PayingClient[]
            setCustomers(payingClients)
            setFilteredCustomers(payingClients)
        })

        db.collection(CONSTANTS.FIRESTORE.MESSAGES).get().then(result => {
            let documents = result.docs.map(doc => doc.data())
            setMessagesAlreadySent(documents as Message[])
        })
    }, [])

    const sendPostcard = (customer: PayingClient) => {
        const messageService = new MessageService()
        messageService.sendPostcard(customer).then(result => {
            if (result == true) {
                setNotification("Postcard setup successfully")
            }
        }).catch(error => {
            setError(error)
        })
    }

    const sendSurvey = (customer: PayingClient) => {

        if (!customer.phoneNumber) { return }
        if (!canSendMessage(customer)) { return }

        let messageText = `Hi ${customer.firstName}! This is Ade with Defined Window Cleaning.

I hope that all is well! We're so happy that we had the privilege of working with you.  We would really like to know how we did and if there's any improvements that we need to make.

If you have a few moments, would you mind filling out a brief 6-question survey? The link to the survey is below.
    
Thank you so much!

${"https://windowcleaning-vegas.com/survey"}`

        let messageService = new MessageService()
        messageService.sendNotification(customer, messageText, CONSTANTS.MESSAGETYPES.REMINDER).then(result => {
            if (result == true) {
                setNotification("Message sent successfully")
            }
        }).catch(error => {
            setError(error)
        })
    }

    const sendReminder = (customer: PayingClient) => {

        if (!customer.phoneNumber) { return }
        if (!canSendMessage(customer)) { return }

        let messageText = `Hi ${customer.firstName}! This is Ade with Defined Window Cleaning.
        
It's been a while since your last cleaning and we want to make sure everything is still looking good. Go ahead and text me back if you'd like to schedule your next cleaning.
            
Thanks!`

        let messageService = new MessageService()
        messageService.sendNotification(customer, messageText, CONSTANTS.MESSAGETYPES.REMINDER).then(result => {
            if (result == true) {
                setNotification("Message sent successfully")
            }
        }).catch(error => {
            setError(error)
        })
    }

    const canSendMessage = (customer: PayingClient) => {
        const message = messagesAlreadySent?.filter(message => message.phoneNumber == customer.phoneNumber)

        if (moment.unix(customer.dateOfService.seconds).diff(new Date()) >= 0) {
            setError("You have not done work for this customer yet.")
            return false
        }

        if (new Date().getHours() < 9) {
            setError("It's too early to send a message to someone.")
            return false
        }

        if (message && message.length > 0) {
            const canNotSendMessage = new MessageService().messageSentWithinThirtyDays(message[0].date)
            if (canNotSendMessage) {
                setError("You have already sent a message to this person within the last 30 days.")
                return false
            }
        }

        return true
    }

    const getIncomingMoney = () => {
        return customers.filter(c => moment.unix(c.dateOfService.seconds).diff(new Date()) > 0).reduce((sum, customer) => sum + (customer.costOfService ?? 0), 0)
    }

    // Function to show the services modal
    const openServicesModal = (services: string[], customerName: string) => {
        setSelectedServices(services || [])
        setSelectedCustomerName(customerName)
        setShowServicesModal(true)
    }

    // Filter customers based on search term
    const filterCustomers = (searchText: string) => {
        setSearchTerm(searchText)

        if (!searchText.trim()) {
            setFilteredCustomers(customers)
            setCurrentPage(1)
            return
        }

        const search = searchText.toLowerCase()
        const filtered = customers.filter(customer => {
            return (
                (customer.firstName?.toLowerCase().includes(search)) ||
                (customer.lastName?.toLowerCase().includes(search)) ||
                (customer.address?.toLowerCase().includes(search)) ||
                (customer.phoneNumber?.toLowerCase().includes(search)) ||
                (customer.email?.toLowerCase().includes(search)) ||
                (customer.servicesProvided?.join(" ").toLowerCase().includes(search)) ||
                (moment.unix(customer.dateOfService.seconds).format("MM/DD/YYYY").includes(search))
            )
        })

        setFilteredCustomers(filtered)
        setCurrentPage(1)
    }

    // Filter surveys based on search term
    const filterSurveys = (searchText: string) => {
        setSurveySearchTerm(searchText)

        if (!searchText.trim() || !surveys) {
            setFilteredSurveys(surveys || [])
            setCurrentSurveyPage(1)
            return
        }

        const search = searchText.toLowerCase()
        const filtered = surveys.filter(survey => {
            return (
                (survey.name?.toLowerCase().includes(search)) ||
                (survey.email?.toLowerCase().includes(search)) ||
                (survey.enjoyedAboutService?.toLowerCase().includes(search)) ||
                (survey.improvements?.toLowerCase().includes(search)) ||
                (survey.moneysWorth?.toLowerCase().includes(search)) ||
                (survey.servicesProvided?.join(" ").toLowerCase().includes(search)) ||
                (survey.rating?.toString().includes(search))
            )
        })

        setFilteredSurveys(filtered)
        setCurrentSurveyPage(1)
    }

    // Get current customers for pagination
    const indexOfLastCustomer = currentPage * customersPerPage
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer)
    const totalCustomerPages = Math.ceil(filteredCustomers.length / customersPerPage)

    // Get current surveys for pagination
    const indexOfLastSurvey = currentSurveyPage * surveysPerPage
    const indexOfFirstSurvey = indexOfLastSurvey - surveysPerPage
    const currentSurveys = filteredSurveys.slice(indexOfFirstSurvey, indexOfLastSurvey)
    const totalSurveyPages = Math.ceil(filteredSurveys.length / surveysPerPage)

    // Change page
    const paginateCustomers = (pageNumber: number) => setCurrentPage(pageNumber)
    const paginateSurveys = (pageNumber: number) => setCurrentSurveyPage(pageNumber)

    return (
        <div style={{ backgroundColor: "white" }} className="ml-5">
            <Toast show={error != undefined} onClose={() => setError(undefined)} className="fixed-top">
                <Toast.Header>
                    <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{error}</Toast.Body>
            </Toast>

            <Toast show={notification != undefined} onClose={() => setNotification(undefined)} className="fixed-top">
                <Toast.Header>
                    <strong className="me-auto">Notification</strong>
                </Toast.Header>
                <Toast.Body>{notification}</Toast.Body>
            </Toast>

            <div>
                <Container fluid className="large-screen px-4">
                    <Row className="mb-4">
                        <Col xs="12">
                            <h1>CRM Dashboard</h1>
                        </Col>
                        <Col xs="12">
                            <Nav variant="tabs" className="mt-3">
                                <Nav.Item>
                                    <Nav.Link
                                        active={activeTab === 'customers'}
                                        onClick={() => setActiveTab('customers')}
                                    >
                                        Customers
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        active={activeTab === 'surveys'}
                                        onClick={() => setActiveTab('surveys')}
                                    >
                                        Surveys
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>

                    {activeTab === 'customers' && (
                        <>
                            <Row>
                                <Col xs="12">
                                    <p className="mb-3">
                                        These are all the customers that are already in the system. Either we've done work for them or we will be doing work for them in the future.
                                    </p>
                                </Col>
                            </Row>

                            <div className="stats-container mt-4 mb-4">
                                <div className="stats-header">
                                    <h5 className="mb-3">Business Stats</h5>
                                </div>
                                <div className="stats-grid">
                                    <div className="stat-card">
                                        <div className="stat-title">Total Customers</div>
                                        <div className="stat-value">{customers.length}</div>
                                    </div>
                                    <div className="stat-card">
                                        <div className="stat-title">Projected Income</div>
                                        <div className="stat-value">${getIncomingMoney()}</div>
                                    </div>
                                    <div className="stat-card">
                                        <div className="stat-title">Customer Surveys</div>
                                        <div className="stat-value">{surveys?.length || 0}</div>
                                    </div>
                                    <div className="stat-card">
                                        <div className="stat-title">This Month</div>
                                        <div className="stat-value">{
                                            customers.filter(c => {
                                                const date = new Date(c.dateOfService.seconds * 1000);
                                                const now = new Date();
                                                return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
                                            }).length
                                        }</div>
                                    </div>
                                </div>
                            </div>

                            <Row className="mt-4">
                                <Col xs="12" className="mb-3">
                                    <div className="header-with-button">
                                        <h3>Customer List</h3>
                                        <Button
                                            variant="primary"
                                            className="rounded-circle add-button"
                                            onClick={createNewCustomer}
                                            title="Create New Customer"
                                            aria-label="Add Customer"
                                        >
                                            +
                                        </Button>
                                    </div>
                                    <InputGroup className="mb-3 mt-3">
                                        <InputGroup.Text id="search-addon">
                                            <span className="material-icons">search</span>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Search customers by name, phone, email, address, date, services..."
                                            value={searchTerm}
                                            onChange={(e) => filterCustomers(e.target.value)}
                                        />
                                        {searchTerm && (
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => filterCustomers("")}
                                            >
                                                Clear
                                            </Button>
                                        )}
                                    </InputGroup>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <small className="text-muted">
                                            Showing {filteredCustomers.length === 0 ? 0 : indexOfFirstCustomer + 1} to {Math.min(indexOfLastCustomer, filteredCustomers.length)} of {filteredCustomers.length} customers
                                        </small>
                                        <Pagination>
                                            <Pagination.First
                                                onClick={() => paginateCustomers(1)}
                                                disabled={currentPage === 1}
                                            />
                                            <Pagination.Prev
                                                onClick={() => paginateCustomers(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            />

                                            {Array.from({ length: Math.min(5, totalCustomerPages) }).map((_, i) => {
                                                let pageNum = 0;
                                                if (totalCustomerPages <= 5) {
                                                    pageNum = i + 1;
                                                } else if (currentPage <= 3) {
                                                    pageNum = i + 1;
                                                } else if (currentPage >= totalCustomerPages - 2) {
                                                    pageNum = totalCustomerPages - 4 + i;
                                                } else {
                                                    pageNum = currentPage - 2 + i;
                                                }

                                                return (
                                                    <Pagination.Item
                                                        key={pageNum}
                                                        active={pageNum === currentPage}
                                                        onClick={() => paginateCustomers(pageNum)}
                                                    >
                                                        {pageNum}
                                                    </Pagination.Item>
                                                );
                                            })}

                                            <Pagination.Next
                                                onClick={() => paginateCustomers(currentPage + 1)}
                                                disabled={currentPage === totalCustomerPages || totalCustomerPages === 0}
                                            />
                                            <Pagination.Last
                                                onClick={() => paginateCustomers(totalCustomerPages)}
                                                disabled={currentPage === totalCustomerPages || totalCustomerPages === 0}
                                            />
                                        </Pagination>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <Table responsive hover size="sm" className="w-100">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Address</th>
                                                <th>Phone</th>
                                                <th className="date-cell">Date</th>
                                                <th className="price-cell">Price</th>
                                                <th>Services</th>
                                                <th>Communication</th>
                                                <th className="actions-cell" style={{ textAlign: "center" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentCustomers.map((customer, index) => {
                                                    // Calculate real index for the current page
                                                    const displayIndex = indexOfFirstCustomer + index;
                                                    const isExpanded = expandedCustomerRows[displayIndex] || false;
                                                    return (
                                                        <tr
                                                            key={displayIndex}
                                                            className={isExpanded ? "expanded-row" : "collapsed-row"}
                                                            onClick={() => {
                                                                setExpandedCustomerRows({
                                                                    ...expandedCustomerRows,
                                                                    [displayIndex]: !isExpanded
                                                                });
                                                            }}
                                                        >
                                                            <td>{displayIndex + 1}</td>
                                                            <td>{customer.firstName}</td>
                                                            <td>{customer.lastName}</td>
                                                            <td>
                                                                {isExpanded 
                                                                    ? customer.address 
                                                                    : customer.address?.substring(0, 20) + (customer.address && customer.address.length > 20 ? '...' : '')}
                                                            </td>
                                                            <td>{customer.phoneNumber}</td>
                                                            <td>{moment.unix(customer.dateOfService.seconds).format("MM/DD/YYYY")}</td>
                                                            <td>${customer.costOfService}</td>
                                                            <td
                                                                className="services-cell"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openServicesModal(
                                                                        customer.servicesProvided || [],
                                                                        `${customer.firstName} ${customer.lastName ?? ""}`
                                                                    );
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <div className="d-flex align-items-center">
                                                                    <span className="me-1">
                                                                        {
                                                                            (customer.servicesProvided?.length || 0) > 0
                                                                                ? isExpanded
                                                                                    ? customer.servicesProvided?.[0]
                                                                                    : (customer.servicesProvided?.[0] ?? "").length > 30
                                                                                        ? customer.servicesProvided?.[0].substring(0, 30) + '...'
                                                                                        : customer.servicesProvided?.[0]
                                                                                : 'None'
                                                                        }

                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    {(customer.servicesProvided?.length || 0) > 1 && (
                                                                        <span className="badge bg-secondary rounded-pill ms-1">
                                                                            +{customer.servicesProvided!.length - 1}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="communication-cell">
                                                                {!isExpanded ?
                                                                    (messagesAlreadySent?.filter(message => message.phoneNumber == customer.phoneNumber).length || 0) + " messages" :
                                                                    messagesAlreadySent?.filter(message => message.phoneNumber == customer.phoneNumber).map(message => {
                                                                        return (
                                                                            <div>
                                                                                <small>
                                                                                    • Sent <strong>{message.type}</strong> on {moment.unix(customer.dateOfService.seconds).format("MM/DD/YY")}
                                                                                </small>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td className="actions-cell" onClick={(e) => e.stopPropagation()}>
                                                                {!isExpanded ? (
                                                                    <MaterialButton size="small" fullWidth={true} style={{ fontSize: '0.7rem', padding: '4px 8px' }} variant="contained">Actions ▼</MaterialButton>
                                                                ) : (
                                                                    <div className="d-grid gap-1">
                                                                        <MaterialButton size="small" fullWidth={true} style={{ fontSize: '0.7rem', padding: '4px 8px' }} variant="contained" color='primary' onClick={() => sendSurvey(customer)}>Survey</MaterialButton>
                                                                        <MaterialButton size="small" fullWidth={true} style={{ fontSize: '0.7rem', padding: '4px 8px' }} variant="contained" color='info' onClick={() => sendReminder(customer)}>Reminder</MaterialButton>
                                                                        <MaterialButton size="small" fullWidth={true} style={{ fontSize: '0.7rem', padding: '4px 8px' }} color='primary' variant="contained" onClick={() => sendPostcard(customer)}>Postcard</MaterialButton>
                                                                        <MaterialButton size="small" fullWidth={true} style={{ fontSize: '0.7rem', padding: '4px 8px' }} variant="contained" onClick={() => {
                                                                            setShowCustomerDialog(true)
                                                                            setSelectedCustomer(customer)
                                                                        }}>Edit</MaterialButton>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs="12" className="d-flex justify-content-between align-items-center mt-3 mb-5">
                                    <small className="text-muted">
                                        Showing {filteredCustomers.length === 0 ? 0 : indexOfFirstCustomer + 1} to {Math.min(indexOfLastCustomer, filteredCustomers.length)} of {filteredCustomers.length} customers
                                    </small>
                                    <Pagination>
                                        <Pagination.First
                                            onClick={() => paginateCustomers(1)}
                                            disabled={currentPage === 1}
                                        />
                                        <Pagination.Prev
                                            onClick={() => paginateCustomers(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        />

                                        {Array.from({ length: Math.min(5, totalCustomerPages) }).map((_, i) => {
                                            let pageNum = 0;
                                            if (totalCustomerPages <= 5) {
                                                pageNum = i + 1;
                                            } else if (currentPage <= 3) {
                                                pageNum = i + 1;
                                            } else if (currentPage >= totalCustomerPages - 2) {
                                                pageNum = totalCustomerPages - 4 + i;
                                            } else {
                                                pageNum = currentPage - 2 + i;
                                            }

                                            return (
                                                <Pagination.Item
                                                    key={pageNum}
                                                    active={pageNum === currentPage}
                                                    onClick={() => paginateCustomers(pageNum)}
                                                >
                                                    {pageNum}
                                                </Pagination.Item>
                                            );
                                        })}

                                        <Pagination.Next
                                            onClick={() => paginateCustomers(currentPage + 1)}
                                            disabled={currentPage === totalCustomerPages || totalCustomerPages === 0}
                                        />
                                        <Pagination.Last
                                            onClick={() => paginateCustomers(totalCustomerPages)}
                                            disabled={currentPage === totalCustomerPages || totalCustomerPages === 0}
                                        />
                                    </Pagination>
                                </Col>
                            </Row>
                        </>
                    )}

                    {activeTab === 'surveys' && (
                        <>
                            <Row className="mt-4">
                                <Col xs="12" className="mb-3">
                                    <h3>Customer Surveys</h3>
                                    <p>This section shows all survey responses from customers.</p>
                                    <InputGroup className="mb-3 mt-3">
                                        <InputGroup.Text id="survey-search-addon">
                                            <span className="material-icons">search</span>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Search surveys by name, email, comments, services..."
                                            value={surveySearchTerm}
                                            onChange={(e) => filterSurveys(e.target.value)}
                                        />
                                        {surveySearchTerm && (
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => filterSurveys("")}
                                            >
                                                Clear
                                            </Button>
                                        )}
                                    </InputGroup>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <small className="text-muted">
                                            Showing {filteredSurveys.length === 0 ? 0 : indexOfFirstSurvey + 1} to {Math.min(indexOfLastSurvey, filteredSurveys.length)} of {filteredSurveys.length} surveys
                                        </small>
                                        <Pagination>
                                            <Pagination.First
                                                onClick={() => paginateSurveys(1)}
                                                disabled={currentSurveyPage === 1}
                                            />
                                            <Pagination.Prev
                                                onClick={() => paginateSurveys(currentSurveyPage - 1)}
                                                disabled={currentSurveyPage === 1}
                                            />

                                            {Array.from({ length: Math.min(5, totalSurveyPages) }).map((_, i) => {
                                                let pageNum = 0;
                                                if (totalSurveyPages <= 5) {
                                                    pageNum = i + 1;
                                                } else if (currentSurveyPage <= 3) {
                                                    pageNum = i + 1;
                                                } else if (currentSurveyPage >= totalSurveyPages - 2) {
                                                    pageNum = totalSurveyPages - 4 + i;
                                                } else {
                                                    pageNum = currentSurveyPage - 2 + i;
                                                }

                                                return (
                                                    <Pagination.Item
                                                        key={pageNum}
                                                        active={pageNum === currentSurveyPage}
                                                        onClick={() => paginateSurveys(pageNum)}
                                                    >
                                                        {pageNum}
                                                    </Pagination.Item>
                                                );
                                            })}

                                            <Pagination.Next
                                                onClick={() => paginateSurveys(currentSurveyPage + 1)}
                                                disabled={currentSurveyPage === totalSurveyPages || totalSurveyPages === 0}
                                            />
                                            <Pagination.Last
                                                onClick={() => paginateSurveys(totalSurveyPages)}
                                                disabled={currentSurveyPage === totalSurveyPages || totalSurveyPages === 0}
                                            />
                                        </Pagination>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <Table responsive hover className="w-100">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Survey</th>
                                                <th>Date of Survey</th>
                                                <th>Services</th>
                                                <th>Referrals</th>
                                                <th>Contacted Referrals</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentSurveys.map((survey, index) => {
                                                    // Calculate real index for the current page
                                                    const displayIndex = indexOfFirstSurvey + index;
                                                    const isExpanded = expandedSurveyRows[displayIndex] || false;
                                                    return (
                                                        <tr
                                                            key={displayIndex}
                                                            style={{ backgroundColor: "white" }}
                                                            className={isExpanded ? "expanded-row" : "collapsed-row"}
                                                            onClick={() => {
                                                                setExpandedSurveyRows({
                                                                    ...expandedSurveyRows,
                                                                    [displayIndex]: !isExpanded
                                                                });
                                                            }}
                                                        >
                                                            <td>{displayIndex + 1}</td>
                                                            <td>{survey.name ?? ""}</td>
                                                            <td>{survey.email ?? ""}</td>
                                                            <td>
                                                                {!isExpanded ? (
                                                                    <span>Click to view <span className="row-toggle">▼</span></span>
                                                                ) : (
                                                                    <>
                                                                        <small className="d-block">
                                                                            •  Enjoyed: {survey.enjoyedAboutService}
                                                                        </small>
                                                                        <small className="d-block">
                                                                            •  Value: {survey.moneysWorth}
                                                                        </small>
                                                                        <small className="d-block">
                                                                            •  Improve: {survey.improvements}
                                                                        </small>
                                                                        <small className="d-block">
                                                                            •  Rating: {survey.rating}
                                                                        </small>
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>{moment.unix(survey.dateAdded?.seconds).format("MM/DD/YYYY")}</td>
                                                            <td>
                                                                {!isExpanded ?
                                                                    (survey.servicesProvided?.[0] ?? "".length > 30
                                                                        ? survey.servicesProvided?.[0] ?? "".substring(0, 30) + '...'
                                                                        : survey.servicesProvided?.[0] || '') :
                                                                    survey.servicesProvided?.join(", ")
                                                                }
                                                            </td>
                                                            <td>
                                                                {!isExpanded ?
                                                                    ((survey.referrals?.length || 0) + " referrals") :
                                                                    survey.referrals?.map(referral => {
                                                                        return (
                                                                            <div>
                                                                                <small>{referral.name} - {referral.contactInfo}</small>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    survey.contactedReferrals == true ? "Yes" : "No"
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs="12" className="d-flex justify-content-between align-items-center mt-3 mb-5">
                                    <small className="text-muted">
                                        Showing {filteredSurveys.length === 0 ? 0 : indexOfFirstSurvey + 1} to {Math.min(indexOfLastSurvey, filteredSurveys.length)} of {filteredSurveys.length} surveys
                                    </small>
                                    <Pagination>
                                        <Pagination.First
                                            onClick={() => paginateSurveys(1)}
                                            disabled={currentSurveyPage === 1}
                                        />
                                        <Pagination.Prev
                                            onClick={() => paginateSurveys(currentSurveyPage - 1)}
                                            disabled={currentSurveyPage === 1}
                                        />

                                        {Array.from({ length: Math.min(5, totalSurveyPages) }).map((_, i) => {
                                            let pageNum = 0;
                                            if (totalSurveyPages <= 5) {
                                                pageNum = i + 1;
                                            } else if (currentSurveyPage <= 3) {
                                                pageNum = i + 1;
                                            } else if (currentSurveyPage >= totalSurveyPages - 2) {
                                                pageNum = totalSurveyPages - 4 + i;
                                            } else {
                                                pageNum = currentSurveyPage - 2 + i;
                                            }

                                            return (
                                                <Pagination.Item
                                                    key={pageNum}
                                                    active={pageNum === currentSurveyPage}
                                                    onClick={() => paginateSurveys(pageNum)}
                                                >
                                                    {pageNum}
                                                </Pagination.Item>
                                            );
                                        })}

                                        <Pagination.Next
                                            onClick={() => paginateSurveys(currentSurveyPage + 1)}
                                            disabled={currentSurveyPage === totalSurveyPages || totalSurveyPages === 0}
                                        />
                                        <Pagination.Last
                                            onClick={() => paginateSurveys(totalSurveyPages)}
                                            disabled={currentSurveyPage === totalSurveyPages || totalSurveyPages === 0}
                                        />
                                    </Pagination>
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </div>

            <div>
                {
                    showCustomerDiaolog && <Customer showModal={showCustomerDiaolog} setShowModal={setShowCustomerDialog} customer={selectedCustomer} />
                }

                {/* Services Modal */}
                <Modal
                    show={showServicesModal}
                    onHide={() => setShowServicesModal(false)}
                    centered
                    size="lg"
                    aria-labelledby="services-modal"
                >
                    <Modal.Header closeButton className="services-modal-header">
                        <Modal.Title id="services-modal">
                            Services for {selectedCustomerName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-4">
                        {selectedServices.length === 0 ? (
                            <div className="text-center py-4 text-muted">
                                <i className="material-icons d-block mb-3" style={{ fontSize: '48px' }}>home_repair_service</i>
                                <p>No services found for this customer</p>
                            </div>
                        ) : (
                            <div className="services-list">
                                <ul className="list-group">
                                    {selectedServices.map((service, index) => (
                                        <li key={index} className="list-group-item d-flex align-items-center">
                                            <div className="service-number me-3">{index + 1}</div>
                                            <span>{service}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowServicesModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    )

}

export default CRM