import { Alert, Button, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal, Card, CardGroup, CardColumns } from 'react-bootstrap'
import { Appointment, PayingClient } from '../models/models'
import Customer from '../components/customer'
import './appointments.css'
import moment from 'moment'
import { Logtail } from '@logtail/browser'
import { AccessTimeOutlined, AccountCircleOutlined, ArrowCircleLeftOutlined, ArrowCircleRightOutlined, CleaningServices, LocationOnOutlined, PaidOutlined } from '@mui/icons-material'
import CONSTANTS from '../constants'

// Add clientCache to the Window interface
declare global {
    interface Window {
        clientCache?: {
            [key: string]: PayingClient
        }
    }
}

type Week = {
    Monday: Appointment[],
    Tuesday: Appointment[],
    Wednesday: Appointment[],
    Thursday: Appointment[],
    Friday: Appointment[],
    Saturday: Appointment[]
}

const Appointments = () => {

    const [clients, setClients] = useState<{}>({})

    const [appointments, setAppointments] = useState<Appointment[]>()

    const [selectedAppointment, setSelectedAppointment] = useState<Appointment>()

    const [showCreateAppointment, setShowCreateAppointment] = useState(false)
    
    // State to track which past appointments are expanded
    const [expandedAppointments, setExpandedAppointments] = useState<{[key: string]: boolean}>({})

    const logtail = new Logtail("QfdZVpuGoBnDV3GopF7y9oos")

    if (firebase.apps.length == 0) {
        logtail.info("Initializing firebase")
        // Initialize Cloud Firestore through Firebase
        firebase.initializeApp({
            apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
            authDomain: 'dephyned-web.firebaseapp.com',
            projectId: 'dephyned-web'
        });
    }

    const [week, setWeek] = useState<Week>({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: []
    })

    let [currentWeek, setCurrentWeek] = useState(0)

    const getAppointments = async (from: Date, to: Date) => {
        const db = firebase.firestore()

        logtail.info("Getting appointments")

        try {
            // Get all the appointments that are after yesterday
            const myAppointments = (await db.collection('appointments').where('dateOfService', '>', from).where('dateOfService', '<', to)
                .get()).docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as Appointment
                }).map(appointment => {
                    return {
                        ...appointment,
                        dateOfService: moment(appointment.dateOfService.toDate())
                    }
                })

            logtail.info("Retrieved appointments", {
                appointments: myAppointments.map(appointment => appointment.id).join(',')
            })

            // Process client data for these appointments
            const newClients = { ...clients }

            // Fetch all missing client data at once
            await Promise.all(myAppointments.map(async (appointment) => {
                if (!newClients[appointment.id]) {
                    const customer = await loadCustomer(appointment.clientId, appointment.id)
                    if (customer) {
                        newClients[appointment.id] = customer
                    }
                }
            }))

            // Update clients first so they're available when appointments render
            setClients(newClients)

            // Then update appointments and week data
            setAppointments(myAppointments)

            const myWeek: Week = {
                Monday: myAppointments.filter(appointment => appointment.dateOfService.day() == 1),
                Tuesday: myAppointments.filter(appointment => appointment.dateOfService.day() == 2),
                Wednesday: myAppointments.filter(appointment => appointment.dateOfService.day() == 3),
                Thursday: myAppointments.filter(appointment => appointment.dateOfService.day() == 4),
                Friday: myAppointments.filter(appointment => appointment.dateOfService.day() == 5),
                Saturday: myAppointments.filter(appointment => appointment.dateOfService.day() == 6)
            }

            setWeek(myWeek)
        } catch (error) {
            console.error("Error fetching appointments:", error)
        }
    }

    useEffect(() => {
        setAppointments(undefined)
        getAppointments(moment().startOf('week').add(7 * currentWeek, 'days').toDate(), moment().endOf('week').add(7 * currentWeek, 'days').toDate())

    }, [currentWeek])

    useEffect(() => {

        getAppointments(moment().startOf('week').toDate(), moment().endOf('week').toDate())
    }, [])

    // We've moved client loading into the getAppointments function
    // to ensure client data is available before rendering appointments
    // This removes the potential race condition


    /**
     * Creates or updates an appointment object, then adds/updates it in the database and the state
     * @param {Appointment} appointment - The appointment to create or update
     * @param {PayingClient} client - PayingClient information
     */
    const createAppointment = (appointment: Appointment, client: PayingClient) => {
        const isUpdating = selectedAppointment && selectedAppointment.id === appointment.id

        logtail.info(isUpdating ? "Updating appointment" : "Creating appointment", {
            appointmentId: appointment.id,
            clientId: client.id
        })

        const db = firebase.firestore()

        db.collection('appointments').doc(appointment.id).set(appointment).then(() => {
            console.log(isUpdating ? 'Appointment updated' : 'Appointment created')

            if (isUpdating) {
                // Replace the old appointment with the updated one
                setAppointments(appointments?.map(app =>
                    app.id === appointment.id ? appointment : app
                ))
            } else {
                // Add the new appointment to the list
                setAppointments([...appointments ?? [], appointment])
            }

            logtail.info(isUpdating ? "Appointment updated" : "Appointment created", {
                appointmentId: appointment.id,
                clientId: client.id
            })
        }).catch((error) => {
            console.log(error)

            logtail.error(`Error ${isUpdating ? 'updating' : 'creating'} appointment`, {
                appointmentId: appointment.id,
                clientId: client.id,
                error: error
            })
        })

        if (!isUpdating) {
            // Only add to state array if it's a new appointment
            setAppointments([...appointments ?? [], appointment])
        }
    }

    const getAppointmentTypeId = (hours: number, services: string[]) => {

        if (services.some(service => service.includes("Screen"))) {
            switch (hours) {
                case 1: return 41128191
                case 2: return 41128204
                case 3: return 41128220
                case 4: return 41128228
                case 5: return 41128231
                case 6: return 41128236
                case 7: return 41128443
                default: return 41128448
            }
        }
        else if (services.some(service => service.includes("Window"))) {
            switch (hours) {
                case 1: return 16635721
                case 2: return 39980877
                case 3: return 40345092
                case 4: return 40345584
                case 5: return 40345587
                case 6: return 40345591
                case 7: return 41128406
                default: return 41128433
            }
        }
    }

    /**
     * It takes an appointment and a client, and adds the appointment to Accuity
     * @param {Appointment} appointment - Appointment - This is the appointment object that was created in
     * the previous step.
     * @param {PayingClient} client - PayingClient - This is the client that the appointment is created for
     */
    const addAppointmentToAccuity = async (appointment: Appointment, client: PayingClient) => {
        const startDate = moment(appointment.dateOfService).format('YYYY-MM-DD')
        const startTime = moment(`${startDate} ${appointment.startTime}`).format('YYYY-MM-DD HH:mm')
        const endTime = moment(`${startDate} ${appointment.endTime}`).format('YYYY-MM-DD HH:mm')

        const hours = moment.duration(moment(endTime).diff(moment(startTime))).asHours()

        let body = {
            smsOptIn: false,
            datetime: startTime,
            appointmentTypeID: getAppointmentTypeId(hours, appointment.servicesProvided),
            calendarID: 4408364,
            firstName: client.firstName,
            lastName: client.lastName,
            phone: client.phoneNumber ?? ""
        }

        if (client.email) {
            body["email"] = client.email
        }

        // Add the appointment to Accuity
        const response = await fetch('https://defined-cleaning.herokuapp.com/addToAccuity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(body)
        })

        console.log(await response.json())
    }

    /**
     * Selects an appointment for editing.
     * This loads the appointment data into the Customer component
     * for editing purposes.
     * @param {Appointment} appointment - The appointment to edit
     */
    const selectAppointment = (appointment: Appointment) => {
        setSelectedAppointment(appointment)
        setShowCreateAppointment(true)
    }

    const sortAppointmentsByTime = (appointments: Appointment[]) => {

        const sorted = appointments.sort((a, b) => {
            const aTime = moment(a.startTime, "HH:mm")
            const bTime = moment(b.startTime, "HH:mm")

            if (aTime.isBefore(bTime)) return -1
            return 1
        })

        return sorted
    }

    /**
     * Loads a customer from the database.
     * Uses a client-side cache to avoid redundant fetches.
     * @param {string} id - the id of the client
     * @param {string} appointmentId - the id of the appointment
     * @returns The customer object
     */
    const loadCustomer = async (id: string, appointmentId: string) => {
        // Create a client cache if it doesn't exist in component scope
        if (!window.clientCache) {
            window.clientCache = {}
        }

        // Check if client is already in cache
        if (window.clientCache[id]) {
            return window.clientCache[id]
        }

        // Fetch from database if not in cache
        const db = firebase.firestore()
        try {
            const customerDoc = await db.collection('clients').doc(id).get()
            if (customerDoc.exists) {
                const customer = customerDoc.data() as PayingClient
                // Store in cache for future use
                window.clientCache[id] = customer
                return customer
            }
            return null
        } catch (error) {
            console.error(`Error loading customer with ID ${id}:`, error)
            return null
        }
    }

    return (
        <div className="large-screen">
            <Container>
                {
                    showCreateAppointment &&
                    <Customer
                        showModal={showCreateAppointment}
                        setShowModal={setShowCreateAppointment}
                        scheduleAppointment={createAppointment}
                        customerId={selectedAppointment?.clientId}
                        existingAppointment={selectedAppointment}
                    />
                }
                <Row>
                    <Col>
                        Defined Home Services
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <div>
                            <h1>Appointments</h1>
                        </div>

                    </Col>
                </Row>
                <Row className="mt-1 mb-5">
                    <Col>
                        <Button variant='contained' onClick={() => {
                            setSelectedAppointment(undefined)
                            setShowCreateAppointment(true)
                        }}>Create Appointment</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ color: "#2976D2", fontWeight: "normal" }} className="my-2">
                            {
                                currentWeek == 0 &&
                                <span><strong>CURRENT WEEK - </strong></span>
                            }
                            Week of {moment().startOf('week').weekday(7 * currentWeek).format('MMMM Do YYYY')}
                            <Button className="mx-2" variant='contained' onClick={() => setCurrentWeek(currentWeek - 1)}><ArrowCircleLeftOutlined /></Button>
                            <Button className="mx-2" variant='contained' onClick={() => setCurrentWeek(currentWeek + 1)}><ArrowCircleRightOutlined /></Button>
                        </div>
                        <hr />
                    </Col>
                </Row>
                {
                    appointments === undefined &&
                    <Row>
                        <Col>
                            <Alert severity='info'>Loading appointments...</Alert>
                        </Col>
                    </Row>
                }
                {
                    appointments !== undefined && appointments.length == 0 &&
                    <Row>
                        <Col>
                            <Alert severity='info'>No appointments for this week</Alert>
                        </Col>
                    </Row>
                }
                {
                    appointments !== undefined && appointments.length > 0 &&
                    <div>
                        {
                            Object.keys(week).map((day, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            week[day].length == 0 &&
                                            <div key={index} className="my-5">
                                                <Row >
                                                    <Col>
                                                        <h3>{day}</h3>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col>
                                                        <Alert severity='info'>No appointments for this day</Alert>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                            week[day].length > 0 &&
                                            <div className="my-5">
                                                <Row key={index}>
                                                    <Col>
                                                        <h3>{day}</h3>
                                                    </Col>
                                                </Row>
                                                {/* <CardColumns> */}
                                                {

                                                    sortAppointmentsByTime(week[day]).map((appointment: Appointment, index: number) => {
                                                        // Check if appointment is in the past
                                                        const isPastAppointment = appointment.dateOfService.isBefore(moment(), 'day');
                                                        
                                                        // Use the component-level state
                                                        const toggleExpand = (appointmentId: string) => {
                                                            setExpandedAppointments({
                                                                ...expandedAppointments,
                                                                [appointmentId]: !expandedAppointments[appointmentId]
                                                            });
                                                        }

                                                        const isExpanded = expandedAppointments[appointment.id] || false;
                                                        const shouldShowDetails = !isPastAppointment || isExpanded;

                                                        return (
                                                            <div key={appointment.id}>
                                                                <Card className="my-2">
                                                                    <Card.Header 
                                                                        style={{
                                                                            backgroundColor: index === moment().day() - 1 ? "#2976D2" : "white", 
                                                                            color: index === moment().day() - 1 ? "white" : "black",
                                                                            cursor: isPastAppointment ? 'pointer' : 'default'
                                                                        }} 
                                                                        onClick={() => isPastAppointment && toggleExpand(appointment.id)}
                                                                    >
                                                                        <strong>{clients[appointment.id]?.firstName} {clients[appointment.id]?.lastName}</strong>
                                                                        {isPastAppointment && (
                                                                            <span style={{float: 'right'}}>
                                                                                {moment(appointment.startTime, "HH:mm").format('hh:mm A')}
                                                                                {isExpanded ? ' ▲' : ' ▼'}
                                                                            </span>
                                                                        )}
                                                                    </Card.Header>
                                                                    {shouldShowDetails && (
                                                                        <Card.Body>
                                                                            {!isPastAppointment && (
                                                                                <Row className="my-3">
                                                                                    <Col xs="12" lg="4">
                                                                                        <strong>Start Time:</strong>
                                                                                    </Col>
                                                                                    <Col xs="12" lg="8">
                                                                                        {moment(appointment.startTime, "HH:mm").format('hh:mm A')}
                                                                                    </Col>
                                                                                </Row>
                                                                            )}

                                                                            <Row className="my-3">
                                                                                <Col xs="12" lg="4">
                                                                                    <strong>Address:</strong>
                                                                                </Col>
                                                                                <Col xs="12" lg="8">
                                                                                    <div>
                                                                                        <div>
                                                                                            {clients[appointment.id]?.address}
                                                                                        </div>
                                                                                        <div>
                                                                                            <Button target="_blank" className="mt-2" variant="contained" href={`https://www.google.com/maps/dir/?api=1&destination=${clients[appointment.id]?.address}`}>Navigate</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row className="my-3">
                                                                                <Col xs="12" lg="4">
                                                                                    <strong>Notes:</strong>
                                                                                </Col>
                                                                                <Col xs="12" lg="8">
                                                                                    <div>
                                                                                        <div>
                                                                                            {appointment.notes}
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row className="my-3">
                                                                                <Col xs="12" lg="4">
                                                                                    <strong>Services Provided:</strong>
                                                                                </Col>
                                                                                <Col xs="12" lg="8">
                                                                                    - {appointment.servicesProvided.join('\r\n- ')}
                                                                                </Col>
                                                                            </Row>

                                                                            {appointment.total > 0 && (
                                                                                <Row className="my-3">
                                                                                    <Col xs="12" lg="4">
                                                                                        <strong>Total:</strong>
                                                                                    </Col>
                                                                                    <Col xs="12" lg="8">
                                                                                        ${appointment.total}
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                            <Row className="my-3">
                                                                                <Col xs="12">
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={() => selectAppointment(appointment)}
                                                                                    >
                                                                                        Edit Appointment
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    )}
                                                                </Card>
                                                            </div>
                                                        )
                                                    })

                                                }
                                                {/* </CardColumns> */}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </Container >

        </div >
    )
}

export default Appointments
