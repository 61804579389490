import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Table, Form, Modal, Row, Col, Alert, Toast, Pagination, Spinner, InputGroup, Badge } from 'react-bootstrap';
import firebase from 'firebase';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import CONSTANTS from '../constants';
import { EmployeeDocument, EmployeeFile } from '../models/models';
import { TextField } from '@mui/material';

const Employees = () => {
    // State for employee list
    const [employees, setEmployees] = useState<EmployeeDocument[]>([]);
    const [filteredEmployees, setFilteredEmployees] = useState<EmployeeDocument[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    // State for the current employee being edited or added
    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState<EmployeeDocument | null>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        files: [],
        isActive: true
    });

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [employeesPerPage] = useState(10);

    // File upload
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Notifications
    const [error, setError] = useState<string | null>(null);
    const [notification, setNotification] = useState<string | null>(null);

    // State for file view modal
    const [showFileViewModal, setShowFileViewModal] = useState(false);
    const [currentFile, setCurrentFile] = useState<EmployeeFile | null>(null);

    // State for confirmation modals
    const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<{ employeeId: string, fileId: string, fileName: string } | null>(null);
    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
    const [employeeToToggle, setEmployeeToToggle] = useState<EmployeeDocument | null>(null);

    // Expanded rows
    const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        console.log("Current Employee:", currentEmployee);
    }, [currentEmployee])

    useEffect(() => {
        if (firebase.apps.length == 0) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        try {
            const db = firebase.firestore();
            const snapshot = await db.collection(CONSTANTS.FIRESTORE.EMPLOYEES).get();

            const employeeData = snapshot.docs.map(doc => {
                return { ...doc.data() } as EmployeeDocument;
            });

            setEmployees(employeeData);
            setFilteredEmployees(employeeData);
        } catch (error) {
            console.error("Error fetching employees:", error);
            setError("Failed to load employees. Please try again later.");
        }
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);

        if (!term.trim()) {
            setFilteredEmployees(employees);
            return;
        }

        const searchLower = term.toLowerCase();
        const filtered = employees.filter(employee =>
            employee.firstName.toLowerCase().includes(searchLower) ||
            employee.lastName.toLowerCase().includes(searchLower) ||
            employee.email.toLowerCase().includes(searchLower) ||
            (employee.phoneNumber && employee.phoneNumber.includes(searchLower)) ||
            (employee.address && employee.address.toLowerCase().includes(searchLower))
        );

        setFilteredEmployees(filtered);
        setCurrentPage(1);
    };

    const openEmployeeModal = (employee: EmployeeDocument | null = null) => {
        setCurrentEmployee(employee == null ? {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            address: '',
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            files: [],
            isActive: true
        } : employee);
        setUploadFiles([]);
        setShowEmployeeModal(true);
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, checked, type } = e.target as HTMLInputElement;


        console.log("Form change:", name, value, checked, type);


        if (currentEmployee) {
            setCurrentEmployee({
                ...currentEmployee,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFiles = Array.from(e.target.files);
            setUploadFiles([...uploadFiles, ...newFiles]);
        }
    };

    const removeSelectedFile = (index: number) => {
        const updatedFiles = [...uploadFiles];
        updatedFiles.splice(index, 1);
        setUploadFiles(updatedFiles);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setUploading(true);
        setUploadProgress(0);

        if (!currentEmployee) return;

        try {
            const db = firebase.firestore();
            const storage = firebase.storage();

            // Prepare employee data
            const isNewEmployee = !currentEmployee.id;
            const employeeId = currentEmployee.id || uuidv4();

            const employeeData: EmployeeDocument = {
                ...currentEmployee,
                id: employeeId,
                dateCreated: currentEmployee.dateCreated || firebase.firestore.FieldValue.serverTimestamp(),
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                files: currentEmployee.files || [],
                isActive: currentEmployee.isActive !== undefined ? currentEmployee.isActive : true
            };

            // Upload files if any
            if (uploadFiles.length > 0) {
                const uploadPromises = uploadFiles.map(async (file, index) => {
                    const fileId = uuidv4();
                    const fileRef = storage.ref(`employees/${employeeId}/${fileId}_${file.name}`);

                    // Upload the file
                    const uploadTask = fileRef.put(file);

                    // Track upload progress
                    return new Promise<EmployeeFile>((resolve, reject) => {
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                const totalProgress = ((index + snapshot.bytesTransferred / snapshot.totalBytes) / uploadFiles.length) * 100;
                                setUploadProgress(totalProgress);
                            },
                            (error) => {
                                reject(error);
                            },
                            async () => {
                                // Get the download URL
                                const downloadUrl = await fileRef.getDownloadURL();

                                // Create file record
                                const fileData: EmployeeFile = {
                                    id: fileId,
                                    name: file.name,
                                    type: file.type,
                                    url: downloadUrl,
                                    dateUploaded: firebase.firestore.FieldValue.serverTimestamp()
                                };

                                resolve(fileData);
                            }
                        );
                    });
                });

                const uploadedFiles = await Promise.all(uploadPromises);
                employeeData.files = [...employeeData.files, ...uploadedFiles];
            }

            // Save employee data to Firestore
            await db.collection(CONSTANTS.FIRESTORE.EMPLOYEES).doc(employeeId).set(employeeData);

            // Reset form and update UI
            setShowEmployeeModal(false);
            setUploadFiles([]);
            setUploadProgress(0);
            setNotification(isNewEmployee ? "Employee added successfully!" : "Employee updated successfully!");

            // Refresh employees list
            fetchEmployees();

        } catch (error) {
            console.error("Error saving employee:", error);
            setError("Failed to save employee. Please try again.");
        } finally {
            setUploading(false);
        }
    };

    const confirmStatusChange = (employee: EmployeeDocument) => {
        setEmployeeToToggle(employee);
        setShowStatusChangeModal(true);
    };

    const toggleEmployeeStatus = async () => {
        if (!employeeToToggle) return;

        try {
            const db = firebase.firestore();

            await db.collection(CONSTANTS.FIRESTORE.EMPLOYEES).doc(employeeToToggle.id).update({
                isActive: !employeeToToggle.isActive,
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });

            fetchEmployees();
            setNotification(`Employee ${employeeToToggle.isActive ? 'deactivated' : 'reactivated'} successfully!`);
            setShowStatusChangeModal(false);
        } catch (error) {
            console.error("Error toggling employee status:", error);
            setError("Failed to update employee status. Please try again.");
            setShowStatusChangeModal(false);
        }
    };

    const openFileViewModal = (file: EmployeeFile) => {
        setCurrentFile(file);
        setShowFileViewModal(true);
    };

    const confirmDeleteFile = (employeeId: string, fileId: string, fileName: string) => {
        setFileToDelete({ employeeId, fileId, fileName });
        setShowDeleteFileModal(true);
    };

    const deleteFile = async () => {
        if (!fileToDelete) return;

        try {
            const db = firebase.firestore();
            const storage = firebase.storage();

            // Get current employee data
            const employeeDoc = await db.collection(CONSTANTS.FIRESTORE.EMPLOYEES).doc(fileToDelete.employeeId).get();
            const employeeData = employeeDoc.data() as EmployeeDocument;

            // Find the file
            const fileIndex = employeeData.files.findIndex(f => f.id === fileToDelete.fileId);
            if (fileIndex === -1) return;

            const file = employeeData.files[fileIndex];

            // Delete from storage
            try {
                // Extract the path from the URL or construct it if known
                const fileRef = storage.refFromURL(file.url);
                await fileRef.delete();
            } catch (storageError) {
                console.error("Error deleting file from storage:", storageError);
                // Continue even if storage delete fails - might be permissions issue
            }

            // Update Firestore document
            const updatedFiles = [...employeeData.files];
            updatedFiles.splice(fileIndex, 1);

            await db.collection(CONSTANTS.FIRESTORE.EMPLOYEES).doc(fileToDelete.employeeId).update({
                files: updatedFiles,
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp()
            });

            // If viewing file details, close the modal
            if (showFileViewModal && currentFile?.id === fileToDelete.fileId) {
                setShowFileViewModal(false);
            }

            // If editing the employee, update the current employee state
            if (showEmployeeModal && currentEmployee?.id === fileToDelete.employeeId) {
                setCurrentEmployee({
                    ...currentEmployee,
                    files: updatedFiles
                });
            }

            fetchEmployees();
            setNotification("File deleted successfully!");
            setShowDeleteFileModal(false);
        } catch (error) {
            console.error("Error deleting file:", error);
            setError("Failed to delete file. Please try again.");
            setShowDeleteFileModal(false);
        }
    };

    // Pagination logic
    const indexOfLastEmployee = currentPage * employeesPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
    const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);
    const totalPages = Math.ceil(filteredEmployees.length / employeesPerPage);

    // Toggle expanded row
    const toggleRow = (index: number) => {
        setExpandedRows({
            ...expandedRows,
            [index]: !expandedRows[index]
        });
    };

    return (
        <div style={{ backgroundColor: "white" }} className="px-4 py-4">
            {/* Notifications */}
            <Toast
                show={!!error}
                onClose={() => setError(null)}
                className="position-fixed top-0 end-0 m-4 bg-danger text-white"
                delay={5000}
                autohide
            >
                <Toast.Header>
                    <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body className="text-white">{error}</Toast.Body>
            </Toast>

            <Toast
                show={!!notification}
                onClose={() => setNotification(null)}
                className="position-fixed top-0 end-0 m-4 bg-success text-white"
                delay={3000}
                autohide
            >
                <Toast.Header>
                    <strong className="me-auto">Success</strong>
                </Toast.Header>
                <Toast.Body className="text-white">{notification}</Toast.Body>
            </Toast>

            <Container fluid>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Employees</h2>
                    <Button
                        variant="primary"
                        onClick={() => openEmployeeModal()}
                        className="d-flex align-items-center"
                    >
                        <span className="material-icons me-1">add</span>
                        Add Employee
                    </Button>
                </div>

                {/* Search box */}
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <span className="material-icons">search</span>
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="Search employees by name, email, phone, etc."
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    {searchTerm && (
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleSearch("")}
                        >
                            Clear
                        </Button>
                    )}
                </InputGroup>

                {/* Employee table */}
                <div className="table-responsive">
                    <Table hover bordered className="mt-3">
                        <thead className="bg-light">
                            <tr>
                                <th style={{ width: '50px' }}>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th>Files</th>
                                <th style={{ width: '150px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEmployees.length > 0 ? (
                                currentEmployees.map((employee, index) => {
                                    const displayIndex = indexOfFirstEmployee + index;
                                    const isExpanded = expandedRows[displayIndex] || false;

                                    return (
                                        <tr
                                            key={employee.id}
                                            className={isExpanded ? "expanded-row" : ""}
                                            onClick={() => toggleRow(displayIndex)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <td>{displayIndex + 1}</td>
                                            <td>{`${employee.firstName} ${employee.lastName}`}</td>
                                            <td>{employee.email}</td>
                                            <td>{employee.phoneNumber || "—"}</td>
                                            <td>
                                                <Badge
                                                    variant={employee.isActive ? "success" : "secondary"}
                                                    onClick={(e: any) => {
                                                        e.stopPropagation();
                                                        confirmStatusChange(employee);
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {employee.isActive ? "Active" : "Inactive"}
                                                </Badge>
                                            </td>
                                            <td>
                                                {employee.files && employee.files.length > 0 ? (
                                                    <span>
                                                        {employee.files.length} file(s)
                                                        {isExpanded && (
                                                            <div className="mt-2">
                                                                {employee.files.map((file, fileIndex) => (
                                                                    <div key={file.id} className="d-flex align-items-center mb-1">
                                                                        <span
                                                                            className="material-icons me-1 text-primary"
                                                                            style={{ fontSize: '16px' }}
                                                                        >
                                                                            {file.type.includes('pdf') ? 'picture_as_pdf' :
                                                                                file.type.includes('image') ? 'image' : 'insert_drive_file'}
                                                                        </span>
                                                                        <a
                                                                            href="#"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                openFileViewModal(file);
                                                                            }}
                                                                            className="file-link me-2"
                                                                        >
                                                                            {file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name}
                                                                        </a>
                                                                        <Button
                                                                            variant="link"
                                                                            size="sm"
                                                                            className="p-0 ms-auto"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                confirmDeleteFile(employee.id, file.id, file.name);
                                                                            }}
                                                                        >
                                                                            <span className="material-icons text-danger" style={{ fontSize: '16px' }}>delete</span>
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span className="text-muted">No files</span>
                                                )}
                                            </td>
                                            <td onClick={(e) => e.stopPropagation()}>
                                                <div className="d-flex">
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        className="me-2"
                                                        onClick={() => openEmployeeModal(employee)}
                                                    >
                                                        <span className="material-icons" style={{ fontSize: '16px' }}>edit</span>
                                                    </Button>
                                                    <Button
                                                        variant="outline-danger"
                                                        size="sm"
                                                        onClick={() => confirmStatusChange(employee)}
                                                    >
                                                        <span
                                                            className="material-icons"
                                                            style={{ fontSize: '16px' }}
                                                        >
                                                            {employee.isActive ? 'person_off' : 'person'}
                                                        </span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7} className="text-center py-4">
                                        {searchTerm ? (
                                            <div>
                                                <span className="material-icons mb-2" style={{ fontSize: '48px', opacity: 0.5 }}>search_off</span>
                                                <p>No employees found matching "{searchTerm}"</p>
                                                <Button variant="link" onClick={() => handleSearch("")}>Clear search</Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="material-icons mb-2" style={{ fontSize: '48px', opacity: 0.5 }}>people</span>
                                                <p>No employees added yet</p>
                                                <Button variant="primary" onClick={() => openEmployeeModal()}>Add Employee</Button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                {/* Pagination */}
                {filteredEmployees.length > employeesPerPage && (
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <span className="text-muted">
                            Showing {indexOfFirstEmployee + 1} to {Math.min(indexOfLastEmployee, filteredEmployees.length)} of {filteredEmployees.length} employees
                        </span>
                        <Pagination>
                            <Pagination.First
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                            />
                            <Pagination.Prev
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 1}
                            />

                            {Array.from({ length: Math.min(5, totalPages) }).map((_, i) => {
                                let pageNum = 0;
                                if (totalPages <= 5) {
                                    pageNum = i + 1;
                                } else if (currentPage <= 3) {
                                    pageNum = i + 1;
                                } else if (currentPage >= totalPages - 2) {
                                    pageNum = totalPages - 4 + i;
                                } else {
                                    pageNum = currentPage - 2 + i;
                                }

                                return (
                                    <Pagination.Item
                                        key={pageNum}
                                        active={pageNum === currentPage}
                                        onClick={() => setCurrentPage(pageNum)}
                                    >
                                        {pageNum}
                                    </Pagination.Item>
                                );
                            })}

                            <Pagination.Next
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            />
                            <Pagination.Last
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>
                    </div>
                )}
            </Container>

            {/* Employee Modal */}
            <Modal
                show={showEmployeeModal}
                onHide={() => setShowEmployeeModal(false)}
                size="lg"
                centered
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton className="bg-light">
                        <Modal.Title>
                            {currentEmployee && currentEmployee.id ? 'Edit Employee' : 'Add New Employee'}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formFirstName">
                                    <TextField
                                        label="First Name"
                                        required
                                        name="firstName"
                                        value={currentEmployee?.firstName || ''}
                                        onChange={handleFormChange}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        inputProps={{ 'data-testid': 'firstName-input' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formLastName">
                                    <TextField
                                        label="Last Name"
                                        required
                                        name="lastName"
                                        value={currentEmployee?.lastName || ''}
                                        onChange={handleFormChange}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        inputProps={{ 'data-testid': 'lastName-input' }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Group controlId="formEmail">
                                    <TextField
                                        label="Email Address"
                                        required
                                        type="email"
                                        name="email"
                                        value={currentEmployee?.email || ''}
                                        onChange={handleFormChange}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        inputProps={{ 'data-testid': 'email-input' }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formPhone">
                                    <TextField
                                        label="Phone Number"
                                        name="phoneNumber"
                                        value={currentEmployee?.phoneNumber || ''}
                                        onChange={handleFormChange}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        inputProps={{ 'data-testid': 'phone-input' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formStatus" className="mt-4">
                                    <Form.Check
                                        type="switch"
                                        id="active-status"
                                        label="Active Employee"
                                        name="isActive"
                                        checked={currentEmployee?.isActive !== false}
                                        onChange={handleFormChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Group controlId="formAddress">
                                    <TextField
                                        label="Address (Optional)"
                                        name="address"
                                        value={currentEmployee?.address || ''}
                                        onChange={handleFormChange}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        multiline
                                        rows={2}
                                        inputProps={{ 'data-testid': 'address-input' }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Group controlId="formNotes">
                                    <TextField
                                        label="Notes (Optional)"
                                        name="notes"
                                        value={currentEmployee?.notes || ''}
                                        onChange={handleFormChange}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        multiline
                                        rows={3}
                                        inputProps={{ 'data-testid': 'notes-input' }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="mt-4 mb-3">
                            <h5>Document Files</h5>
                            <p className="text-muted small">Upload contracts and other employee documents</p>
                        </div>

                        {currentEmployee && currentEmployee.files && currentEmployee.files.length > 0 && (
                            <div className="mb-4">
                                <div className="file-list p-3 border rounded bg-light">
                                    <h6>Current Files</h6>
                                    {currentEmployee.files.map((file) => (
                                        <div key={file.id} className="d-flex align-items-center mb-2 p-2 border-bottom">
                                            <span className="material-icons me-2 text-primary">
                                                {file.type.includes('pdf') ? 'picture_as_pdf' :
                                                    file.type.includes('image') ? 'image' : 'insert_drive_file'}
                                            </span>
                                            <div>
                                                <div>{file.name}</div>
                                                <small className="text-muted">
                                                    {file.dateUploaded?.seconds ?
                                                        moment.unix(file.dateUploaded.seconds).format('MMM D, YYYY') :
                                                        'Recently added'}
                                                </small>
                                            </div>
                                            <div className="ms-auto">
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    className="me-2"
                                                    onClick={() => openFileViewModal(file)}
                                                >
                                                    <span className="material-icons" style={{ fontSize: '18px' }}>visibility</span>
                                                </Button>
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    className="text-danger"
                                                    onClick={() => confirmDeleteFile(currentEmployee.id, file.id, file.name)}
                                                >
                                                    <span className="material-icons" style={{ fontSize: '18px' }}>delete</span>
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="mb-4">
                            <Form.Group controlId="formFileUpload">
                                <Form.Label>Upload New Files</Form.Label>
                                <div className="d-flex align-items-center mb-3">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileSelect}
                                        style={{ display: 'none' }}
                                        multiple
                                    />
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => fileInputRef.current?.click()}
                                        className="me-2"
                                    >
                                        <span className="material-icons me-1" style={{ fontSize: '20px', verticalAlign: 'sub' }}>
                                            upload_file
                                        </span>
                                        Select Files
                                    </Button>
                                    <span className="text-muted small">
                                        Support for PDF, Word, Excel, images, etc.
                                    </span>
                                </div>

                                {uploadFiles.length > 0 && (
                                    <div className="selected-files p-3 border rounded">
                                        <h6>Files to Upload</h6>
                                        {uploadFiles.map((file, index) => (
                                            <div key={index} className="d-flex align-items-center mb-2 p-2 border-bottom">
                                                <span className="material-icons me-2 text-primary">
                                                    {file.type.includes('pdf') ? 'picture_as_pdf' :
                                                        file.type.includes('image') ? 'image' : 'insert_drive_file'}
                                                </span>
                                                <div>
                                                    <div>{file.name}</div>
                                                    <small className="text-muted">{(file.size / 1024).toFixed(1)} KB</small>
                                                </div>
                                                <Button
                                                    variant="link"
                                                    className="ms-auto text-danger"
                                                    onClick={() => removeSelectedFile(index)}
                                                >
                                                    <span className="material-icons">cancel</span>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Form.Group>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="bg-light">
                        {uploading ? (
                            <div className="w-100">
                                <div className="d-flex align-items-center mb-2">
                                    <Spinner animation="border" size="sm" className="me-2" />
                                    <span>Uploading files... {uploadProgress.toFixed(0)}%</span>
                                </div>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${uploadProgress}%` }}
                                        aria-valuenow={uploadProgress}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between w-100">
                                <Button variant="secondary" onClick={() => setShowEmployeeModal(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="primary">
                                    {currentEmployee && currentEmployee.id ? 'Update Employee' : 'Add Employee'}
                                </Button>
                            </div>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* File View Modal */}
            <Modal
                show={showFileViewModal}
                onHide={() => setShowFileViewModal(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{currentFile?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentFile && (
                        <div className="file-preview text-center">
                            {currentFile.type.includes('pdf') ? (
                                <div className="pdf-container">
                                    <iframe
                                        src={currentFile.url}
                                        width="100%"
                                        height="500px"
                                        title={currentFile.name}
                                        className="border-0"
                                    />
                                </div>
                            ) : currentFile.type.includes('image') ? (
                                <img
                                    src={currentFile.url}
                                    alt={currentFile.name}
                                    className="img-fluid"
                                    style={{ maxHeight: '500px' }}
                                />
                            ) : (
                                <div className="text-center py-5">
                                    <span className="material-icons d-block mb-3" style={{ fontSize: '64px' }}>
                                        description
                                    </span>
                                    <p>Preview not available for this file type</p>
                                    <a
                                        href={currentFile.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary mt-2"
                                    >
                                        Download File
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <div>
                            {currentFile && (
                                <small className="text-muted">
                                    Uploaded on {currentFile.dateUploaded?.seconds ?
                                        moment.unix(currentFile.dateUploaded.seconds).format('MMMM D, YYYY') :
                                        'Unknown date'}
                                </small>
                            )}
                        </div>
                        <div>
                            <a
                                href={currentFile?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline-primary me-2"
                                download={currentFile?.name}
                            >
                                <span className="material-icons me-1" style={{ fontSize: '16px', verticalAlign: 'text-top' }}>
                                    download
                                </span>
                                Download
                            </a>
                            <Button variant="secondary" onClick={() => setShowFileViewModal(false)}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Delete File Confirmation Modal */}
            <Modal
                show={showDeleteFileModal}
                onHide={() => setShowDeleteFileModal(false)}
                centered
                size="sm"
                backdrop="static"
            >
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title as="h5">Delete File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mb-3">
                        <span className="material-icons text-danger" style={{ fontSize: '48px' }}>warning</span>
                    </div>
                    <p>Are you sure you want to delete the file "{fileToDelete?.fileName}"?</p>
                    <p className="text-danger small fw-bold">This action cannot be undone.</p>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={() => setShowDeleteFileModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteFile}>
                        Delete File
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Status Change Confirmation Modal */}
            <Modal
                show={showStatusChangeModal}
                onHide={() => setShowStatusChangeModal(false)}
                centered
                size="sm"
                backdrop="static"
            >
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title as="h5">
                        {employeeToToggle?.isActive ? 'Deactivate' : 'Reactivate'} Employee
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mb-3">
                        <span
                            className="material-icons"
                            style={{
                                fontSize: '48px',
                                color: employeeToToggle?.isActive ? '#dc3545' : '#198754'
                            }}
                        >
                            {employeeToToggle?.isActive ? 'person_off' : 'person'}
                        </span>
                    </div>
                    <p>
                        Are you sure you want to {employeeToToggle?.isActive ? 'deactivate' : 'reactivate'} {' '}
                        <strong>{employeeToToggle?.firstName} {employeeToToggle?.lastName}</strong>?
                    </p>
                    {employeeToToggle?.isActive && (
                        <p className="small">Inactive employees will no longer appear in active reports and lists.</p>
                    )}
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={() => setShowStatusChangeModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant={employeeToToggle?.isActive ? "danger" : "success"}
                        onClick={toggleEmployeeStatus}
                    >
                        {employeeToToggle?.isActive ? 'Deactivate' : 'Reactivate'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Employees;